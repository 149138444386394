<template>
    <div>
        <el-form class="pay-form" :model="payment_config" label-width="100px">
            <el-form-item label="支付金额" prop="amount_money">
                <el-input v-model="payment_config.amount_money" placeholder="请输入金额" @blur="amountMoneyBlur"></el-input>
                <span>提示：可输入部分金额，分多次付款</span>
            </el-form-item>
            <el-form-item label="支付方式" prop="pay_id">
                <el-select v-model="payment_config.pay_id" placeholder="请选择" @change="payChange" >
                    <el-option v-for="item of payment_config.pay_list" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item v-if="payment_config.payment_method === 2 || payment_config.payment_method === 3" label="条形码" prop="paycode">
                <el-input type="text" placeholder="请输入条形码" v-model="payment_config.paycode" @blur="payCodeBlur"></el-input>
            </el-form-item>
            <el-form-item v-else-if="payment_config.payment_method === 1" label="支付设备" prop="equipment_id">
                <el-select v-model="payment_config.equipment_id" placeholder="请选择" @change="deviceChange" >
                    <el-option v-for="item of payment_config.device_list" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <template v-else-if="payment_config.payment_method === 5 || payment_config.payment_method === 6">
                <el-form-item  label="手机号" prop="phone">
                    <el-input type="number" placeholder="请输入手机号" v-model="payment_config.phone" @blur="phoneBlur"></el-input>
                </el-form-item>
                <el-form-item  label="验证码" prop="yzcode">
                    <div class="global-flex">
                        <el-input type="text" placeholder="请输入验证码" v-model="payment_config.yzcode" @blur="yzcodeBlur"></el-input>
                        <el-button @click="getCode">
                            <span v-if="!payment_config.is_send_code">发送验证码</span>
							<span v-else>{{payment_config.seconds}}S</span>
                        </el-button>
                    </div>
                </el-form-item>
            </template>
        </el-form>
    </div>
</template>
<script>
import { defineComponent, onMounted, reactive, toRefs, watch } from 'vue'
import {pubDeskApi,commonApi} from '@/api'
import calculation from "@/assets/js/calculation";
import { compareTime, customFormatFullTime, toDecimal } from "@/assets/js/utils";
import {ElMessage,ElMessageBox} from 'element-plus';
export default defineComponent({
    props:{
        pay_moeny:{
            default:0,
            type:Number
        },
        //1正常 2充值 3挂账
        module_type:{
            default:1, 
            type:Number
        }
    },
    setup(props,context) {
        let pageData = reactive({
            payment_config: {
                from: {},
                pay_id: "",
                payment_method: "",
                amount_money: "", //输入的金额
                type: 0,
                paycode: "", //微信/支付宝
                equipment_num: "",
                equipment_id: "",
                pay_list: [], //支付列表
                device_list: [], //设备列表
                phone:'', //手机号
                yzcode:'', //验证码
                seconds:60, //发送倒计时
                is_send_code:false,//是否发送了
                is_show_pay:false,
                pay_moeny:'', //需要支付的金额
            },
        })

        watch(()=>props.pay_moeny,((newVal)=>{
            pageData.payment_config.pay_moeny = newVal
            pageData.payment_config.amount_money = newVal //赋值到输入框
        }),{
            immediate:true,
            deep:true,
        })

        onMounted(()=>{
            orderEquipment();
            mealGetPayWayList();
        })

        /**
         * 选择支付方式
         */
        function payChange(e) {
            let pay_list = pageData.payment_config.pay_list;
            for (let i in pay_list) {
                if (pay_list[i].id == pageData.payment_config.pay_id) {
                    pageData.payment_config.payment_method = pay_list[i].type;
                    break;
                }
            }
            pageData.payment_config.type = 0
            pageData.payment_config.paycode = ''
            pageData.payment_config.equipment_num = ''
            pageData.payment_config.equipment_id = ''
            change()
        }

        /**
         * 选择设备
         */
        function deviceChange() {
            let device_list = pageData.payment_config.device_list;
            for (let i in device_list) {
                if (device_list[i].id == pageData.payment_config.equipment_id) {
                    pageData.payment_config.equipment_num =
                        device_list[i].equipment_num;
                    break;
                }
            }
            change()
        }

        /**
         * 设备列表
         */
        function orderEquipment() {
            pageData.payment_config.equipment_num = "";
            pageData.payment_config.equipment_id = "";
            pageData.payment_config.device_list = [];
            pubDeskApi.orderEquipment().then((res) => {
                pageData.payment_config.device_list = res.data;
            });
        }

        /**
         * 支付金额 取消焦点
         */
        function amountMoneyBlur() {
            if(pageData.payment_config.amount_money){
                pageData.payment_config.amount_money = toDecimal(pageData.payment_config.amount_money)
            }
            if(pageData.payment_config.amount_money > pageData.payment_config.pay_moeny){
                ElMessage.warning(`支付金额不能大于${pageData.payment_config.pay_moeny}`)
                pageData.payment_config.amount_money = pageData.payment_config.pay_moeny
            }else if(pageData.payment_config.amount_money <= 0){
                ElMessage.warning(`支付金额不能小于0`)
                pageData.payment_config.amount_money = pageData.payment_config.pay_moeny
            }
            change()
        }

        /**
         * 支付方式列表
         */
        function mealGetPayWayList() {
            pubDeskApi.mealGetPayWayList({
                module_type:props.module_type,
            }).then((res) => {
                pageData.payment_config.pay_list = res.data.list;
            });
        }

        /**
         * 条形码 - 失去焦点
         */
        function payCodeBlur(){
            change()
        }

        /**
         * 手机号 - 失去焦点
         */
        function phoneBlur(){
            change()
        }

        /**
         * 验证码 - 失去焦点
         */
        function yzcodeBlur(){
            change()
        }

        /**
         * 通知父组件
         */
        function change(){
            context.emit('payChange',pageData.payment_config)
        }

        /**
         * 验证参数
         */
        function validateParameter(){
            return new Promise((resolve,reject)=>{
                if (!pageData.payment_config.amount_money) {
                    ElMessage.warning("请输入金额");
                    reject()
                    return;
                } else if (parseFloat(pageData.payment_config.amount_money) < 0) {
                    ElMessage.warning("付款金额不能小于0");
                    reject()
                    return;
                } else if (!pageData.payment_config.pay_id) {
                    ElMessage.warning("请选择支付方式");
                    reject()
                    return;
                }

                if (
                    (pageData.payment_config.payment_method === 2 ||
                        pageData.payment_config.payment_method === 3) &&
                    !pageData.payment_config.paycode.trim()
                ) {
                    ElMessage.warning("请输入条形码");
                    reject()
                    return;
                } else if (
                    pageData.payment_config.payment_method === 1 &&
                    !pageData.payment_config.equipment_num.trim()
                ) {
                    ElMessage.warning("请选择POS设备");
                    reject()
                    return;
                } else if(pageData.payment_config.payment_method === 5 || pageData.payment_config.payment_method === 6){
					if(!pageData.payment_config.phone || pageData.payment_config.phone.length != 11){
                        ElMessage.warning("请输入11位手机号码");
                        reject()
						return;
					}else if(!pageData.payment_config.yzcode.trim()){
                        ElMessage.warning("请输入短信验证码");
                        reject()
						return;
					}
				}

                resolve()
            })
            
        }

        /**
         * 发送验证码
         */
        function getCode(){
            if(pageData.payment_config.is_send_code){
                // 验证码已发送
                ElMessage.warning("验证码已发送");
                return
            }
            if(!pageData.payment_config.phone || pageData.payment_config.phone.length != 11){
                ElMessage.warning("请输入11位手机号码");
                return;
            }
            pageData.payment_config.is_send_code = true
            commonApi.smsSendSms({
                phone:pageData.payment_config.phone,
                tempCode:'memberPay',
            }).then(res=>{
                ElMessage.success("发送成功");
                try{
                    let stop = setInterval(()=>{
                        if(pageData.payment_config.seconds <= 0){
                            clearInterval(stop)
                            pageData.payment_config.seconds = 60
                            pageData.payment_config.is_send_code = false
                            return;
                        }
                        pageData.payment_config.seconds = pageData.payment_config.seconds - 1
                    },1000)
                }catch(e){
                    pageData.payment_config.is_send_code = false
                }
            }).catch(err=>{
                pageData.payment_config.is_send_code = false
            })
        }

        return {
            ...toRefs(pageData),
            amountMoneyBlur,
            payChange,
            deviceChange,
            payCodeBlur,
            validateParameter,
            yzcodeBlur,
            phoneBlur,
            getCode
        }
    },
})
</script>
<style lang="scss" scoped>
.pay-form{
    width: 400px;
}
</style>