<template>
    <div class="pub-desk-page">
        <div class="top-info">
            <div class="item">
                <h2>￥{{ market_table.total_money || '0.00' }}</h2>
                <p>今日总营收</p>
            </div>
            <div class="item">
                <h2>￥{{ market_table.actual_amount || '0.00' }}</h2>
                <p>今日实收</p>
            </div>
            <div class="item">
                <h2>￥{{ market_table.total_recharge_amount || '0.00' }}</h2>
                <p>充值总金额</p>
            </div>
            <div class="item">
                <h2>￥{{ market_table.refund_amount || '0.00' }}</h2>
                <p>退款金额</p>
            </div>
            <div class="item" v-for="(item, index) of market_table.payment_detatils" :key="index">
                <h2 class="money" title="收入金额">￥{{ item.money }}</h2>
                <h4 class="refund-money" title="退款金额">-￥{{ item.refund_money }}</h4>
                <p>{{ item.payment_method }}</p>
            </div>
        </div>

        <div class="tab-box-container">
            <div>
                <el-tabs v-model="tab_config.tab_id" type="card" @tab-click="handleClick">
                    <el-tab-pane :label="item.label" :name="item.id" v-for="(item, index) of tab_config.list" :key="index"></el-tab-pane>
                </el-tabs>
            </div>

            <div class="global-mt-20">
                <!-- 订单明细 start -->
                <div v-if="tab_config.tab_id == 0">
                    <el-form :model="search_config.form" :rules="search_config.rules" ref="ruleForm" label-width="100px" class="start-desk-box" :inline="true">
                        <el-form-item label="订单号" prop="order_number">
                            <el-input class="input" v-model="search_config.form.order_number" placeholder="请输入订单号" @keyup.enter="queryFn"></el-input>
                        </el-form-item>
                        <el-form-item label="开台编号" prop="open_room_bill">
                            <el-input class="input" v-model="search_config.form.open_room_bill" placeholder="请输入开台编号" @keyup.enter="queryFn"></el-input>
                        </el-form-item>
                        <el-form-item label="台位号" prop="station_name">
                            <el-input class="input" v-model="search_config.form.station_name" placeholder="请输入台位号" @keyup.enter="queryFn"></el-input>
                        </el-form-item>
                        <el-form-item label="会员信息" prop="member">
                            <el-input class="input" v-model="search_config.form.member" placeholder="请输入会员信息/手机号" @keyup.enter="queryFn"></el-input>
                        </el-form-item>
                        <el-form-item label="订台人" prop="sales_name">
                            <el-input class="input" v-model="search_config.form.sales_name" placeholder="请输入订台人" @keyup.enter="queryFn"></el-input>
                        </el-form-item>
                        <el-form-item label="服务员" prop="waiter_name">
                            <el-input class="input" v-model="search_config.form.waiter_name" placeholder="请输入服务员" @keyup.enter="queryFn"></el-input>
                        </el-form-item>
                        <el-form-item label="时间筛选" prop="times">
                            <el-select v-model="search_config.form.type" placeholder="请选择" class="order-type" @change="typeChange">
                                <el-option v-for="(item,index) of search_config.type_options" :value="item.id" :label="item.label" :key="index"></el-option>
                            </el-select>
                            <el-date-picker :clearable="false" v-if="search_config.form.type == 1" v-model="search_config.form.times" type="datetimerange" start-placeholder="开始时间" end-placeholder="结束时间"></el-date-picker>
                            <el-date-picker :clearable="false" v-else v-model="search_config.form.times" type="daterange" start-placeholder="开始时间" end-placeholder="结束时间"></el-date-picker>
                        </el-form-item>
                        <el-form-item label="订单状态" prop="status">
                            <el-select class="select-box" v-model="search_config.form.status" placeholder="请选择">
                                <el-option
                                    v-for="item of search_config.status_options"
                                    :key="item.id"
                                    :label="item.label"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="后结状态" prop="posterior_knot_status">
                            <el-select class="select-box" v-model="search_config.form.posterior_knot_status" placeholder="请选择">
                                <el-option
                                    v-for="item of search_config.posterior_knot_status_options"
                                    :key="item.id"
                                    :label="item.label"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="">
                            <el-button type="primary" @click="queryFn">查询</el-button>
                            <el-button @click="queryResetFn">重置</el-button>
                            <el-button type="primary" plain @click="exportFun">订单导出</el-button>
                        </el-form-item>
                    </el-form>
                    <el-table :data="trade_order.list" stripe border style="width: 100%">
                        <el-table-column header-align="center" align="center" v-for="(item, index) of trade_order.column_key" :key="index" :prop="item.prop" :label="item.label" :width="item.width ? item.width : null">
                            <template #default="scope">
                                <div v-if="item.prop == 'status_name'">
                                    <el-tag :type="statusNameChange(scope.row)">
                                        <span>{{scope.row['status_name']}}</span>
                                        <span v-if="scope.row['refund_status_name']">,{{scope.row['refund_status_name']}}</span>
                                    </el-tag>
                                </div>
                                <div v-else-if="item.prop == 'member_name'">
                                    <div>{{scope.row.member_name}}</div>
                                    <div>{{scope.row.member_phone}}</div>
                                </div>
                                <div v-else-if="item.prop == 'posterior_knot'">
                                    <span v-if="scope.row[item.prop] == 1">是</span>
                                    <span v-else>否</span>
                                </div>
                                <div v-else-if="item.prop == 'control'" class="control-btn-all">
                                    <el-button size="mini" type="primary" @click="openDetailPopup(scope.row,0)" v-allow="['view']">查看详情</el-button>
                                    <el-button size="mini" type="primary" v-if="scope.row.status != 4 && scope.row.cancelOrder != 1" @click="cancelOrder(scope.row)">取消订单</el-button>
                                    <el-button size="mini" type="danger" @click="openDetailPopup(scope.row,1)" v-allow="['pay']" v-if="(scope.row.posterior_knot && scope.row.status == 1 && scope.row.posterior_knot_status == 2) || (!scope.row.posterior_knot && scope.row.status == 1)">支付</el-button>
                                    <el-button size="mini" type="primary" v-if="scope.row.posterior_knot === 1 && scope.row.posterior_knot_status === 4" @click="orderPostClosingAudit(scope.row,5)" v-allow="['cancelOrderResolve']">通过取消订单</el-button>
                                    <el-button size="mini" type="primary" v-if="scope.row.posterior_knot === 1 && scope.row.posterior_knot_status === 4"  @click="orderPostClosingAudit(scope.row,6)" v-allow="['cancelOrderReject']">拒绝取消订单</el-button>
                                    <el-button size="mini" type="success" v-if="scope.row.status == 2" @click="renewPrintIndex(scope.row,'product')" v-allow="['cpdPrint']">出品单打印</el-button>
                                    <el-button size="mini" type="primary" @click="openChangeDesk(scope.row)">修改台位</el-button>
                                    <!-- 
                                        未支付
                                        是否是后结订单 posterior_knot
                                        posterior_knot_status 1待审核 2通过 3拒绝
                                        -->
                                    <el-button size="mini" type="primary" v-if="scope.row.status != 2 && scope.row.posterior_knot && scope.row.posterior_knot_status === 1" @click="orderPostClosingAudit(scope.row,2)" v-allow="['afterResolve']">后结审核通过</el-button>
                                    <el-button size="mini" type="danger" v-if="scope.row.status != 2 && scope.row.posterior_knot && scope.row.posterior_knot_status === 1" @click="orderPostClosingAudit(scope.row,3)" v-allow="['afterReject']">后结审核拒绝</el-button>
                                </div>
                                <div v-else-if="scope.row[item.prop]">{{scope.row[item.prop]}}</div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>

                <!-- 支付明细 -->
                <div v-else-if="tab_config.tab_id === '0-1' || tab_config.tab_id == 3">
                    <el-form :model="search_config.form" :rules="search_config.rules" ref="ruleForm" label-width="100px" class="start-desk-box" :inline="true">
                        <el-form-item label="订单号" prop="order_number">
                            <el-input class="input" v-model="search_config.form.order_number" placeholder="请输入订单号" @keyup.enter="queryFn"></el-input>
                        </el-form-item>
                        <el-form-item label="开台编号" prop="open_room_bill">
                            <el-input class="input" v-model="search_config.form.open_room_bill" placeholder="请输入开台编号" @keyup.enter="queryFn"></el-input>
                        </el-form-item>
                        <el-form-item label="支付单号" prop="pay_no">
                            <el-input class="input" v-model="search_config.form.pay_no" placeholder="请输入支付单号" @keyup.enter="queryFn"></el-input>
                        </el-form-item>
                        <el-form-item label="台位区域" prop="station_region_name">
                            <el-input class="input" v-model="search_config.form.station_region_name" placeholder="请输入台位区域" @keyup.enter="queryFn"></el-input>
                        </el-form-item>
                        <el-form-item label="台位号" prop="station_name">
                            <el-input class="input" v-model="search_config.form.station_name" placeholder="请输入台位号" @keyup.enter="queryFn"></el-input>
                        </el-form-item>
                        <el-form-item label="会员信息" prop="member">
                            <el-input class="input" v-model="search_config.form.member" placeholder="请输入会员信息/手机号" @keyup.enter.native="queryFn"></el-input>
                        </el-form-item>
                        <el-form-item label="账单类型" prop="bill_type">
                            <el-select class="select-box" v-model="search_config.form.bill_type" placeholder="请选择">
                                <el-option
                                    v-for="item of search_config.bill_type_options"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="支付方式" prop="pay_way_id">
                            <el-select class="select-box" v-model="search_config.form.pay_way_id" placeholder="请选择">
                                <el-option
                                    v-for="item of payment_config.pay_list"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="下单人" prop="waiter_name">
                            <el-input class="input" v-model="search_config.form.waiter_name" placeholder="请输入下单人" @keyup.enter="queryFn"></el-input>
                        </el-form-item>
                        <el-form-item label="交易时间" prop="times">
                            <el-select v-model="search_config.form.type" placeholder="请选择" class="order-type" @change="typeChange">
                                <el-option v-for="(item,index) of search_config.type_options" :value="item.id" :label="item.label" :key="index"></el-option>
                            </el-select>
                            <el-date-picker :clearable="false" v-if="search_config.form.type == 1" v-model="search_config.form.times" type="datetimerange" start-placeholder="开始时间" end-placeholder="结束时间"></el-date-picker>
                            <el-date-picker :clearable="false" v-else v-model="search_config.form.times" type="daterange" start-placeholder="开始时间" end-placeholder="结束时间"></el-date-picker>
                        </el-form-item>
                        <!-- <el-form-item label="交易时间" prop="times">
                            <el-date-picker :clearable="false" v-model="search_config.form.times" type="datetimerange" start-placeholder="开始时间" end-placeholder="结束时间"></el-date-picker>
                        </el-form-item> -->
                        <el-form-item label="">
                            <el-button type="primary" @click="queryFn">查询</el-button>
                            <el-button @click="queryResetFn">重置</el-button>
                        </el-form-item>
                    </el-form>
                    <el-table :data="pay_order.list" stripe border style="width: 100%">
                        <el-table-column header-align="center" align="center" v-for="(item, index) of pay_order.column_key" :key="index" :prop="item.prop" :label="item.label" :width="item.width ? item.width : null">
                            <template #default="scope">
                                <div v-if="item.prop == 'status'">
                                    <el-tag type="danger" v-if="scope.row[item.prop] === 0">未结算</el-tag>
                                    <el-tag type="success" v-else-if=" scope.row[item.prop] === 1">已支付</el-tag>
                                    <el-tag v-else-if="scope.row[item.prop] === 2">失败</el-tag>
                                    <el-tag v-else-if="scope.row[item.prop] === 3">待确认</el-tag>
                                    <el-tag v-else-if=" scope.row[item.prop] === 4">已退回</el-tag>
                                    <el-tag v-else-if="scope.row[item.prop] === 5">异常收款</el-tag>
                                </div>
                                <div v-else-if="item.prop == 'member_name'">
                                    <div>{{scope.row.member_name}}</div>
                                    <div>{{scope.row.phone}}</div>
                                </div>
                                <div v-else-if="item.prop == 'bill_type'">
                                    <span v-if="scope.row.bill_type == 1">订单</span>
                                    <span v-if="scope.row.bill_type == 5">挂账单</span>
                                    <span v-if="scope.row.bill_type == 6">清账单</span>
                                </div>
                                <div v-else-if="item.prop == 'control'">
                                    <el-button size="mini" type="primary" @click="openDetailPopup(scope.row,0)" v-allow="['view']">查看详情</el-button>
                                    <el-button size="mini" type="primary" @click="moneyArgee(scope.row)" v-if="scope.row.status == 3" v-allow="['cashAffirm']">核准确认</el-button>
                                    <el-button size="mini" type="danger" @click="refundMoney(scope.row)" v-if="scope.row.status == 3" v-allow="['return']">退回</el-button>
                                    <el-button size="mini" type="success" @click="renewPrintIndex(scope.row,'pay')" v-allow="['zfdPrint']">支付单重打印</el-button>
                                </div>
                                <div v-else-if="scope.row[item.prop]">{{ scope.row[item.prop] }}</div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>

                <!-- 退换单 start -->
                <div v-if="tab_config.tab_id == 4">
                    <el-form :model="search_config.form" :rules="search_config.rules" ref="ruleForm" label-width="100px" class="start-desk-box" :inline="true" @submit.native.prevent>
                        <el-form-item label="退换单号" prop="return_order_number">
                            <el-input class="input" v-model="search_config.form.return_order_number" placeholder="请输入订单号" @keyup.enter="queryFn"></el-input>
                        </el-form-item>
                        <el-form-item label="订单号" prop="order_number">
                            <el-input class="input" v-model="search_config.form.order_number" placeholder="请输入订单号" @keyup.enter="queryFn"></el-input>
                        </el-form-item>
                        <el-form-item label="会员信息" prop="member">
                            <el-input class="input" v-model="search_config.form.member" placeholder="请输入会员信息/手机号" @keyup.enter.native="queryFn"></el-input>
                        </el-form-item>
                        <el-form-item label="台位号" prop="station_name">
                            <el-input class="input" v-model="search_config.form.station_name" placeholder="请输入台位号" @keyup.enter="queryFn"></el-input>
                        </el-form-item>
                        <el-form-item label="类型" prop="refund_type">
                            <el-select v-model="search_config.form.refund_type" placeholder="请选择" class="order-type">
                                <el-option v-for="(item,index) of search_config.refund_type_options" :value="item.id" :label="item.label" :key="index"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="申请时间" prop="times">
                            <el-select v-model="search_config.form.type" placeholder="请选择" class="order-type" @change="typeChange">
                                <el-option v-for="(item,index) of search_config.type_options" :value="item.id" :label="item.label" :key="index"></el-option>
                            </el-select>
                            <el-date-picker :clearable="false" v-if="search_config.form.type == 1" v-model="search_config.form.times" type="datetimerange" start-placeholder="开始时间" end-placeholder="结束时间"></el-date-picker>
                            <el-date-picker :clearable="false" v-else v-model="search_config.form.times" type="daterange" start-placeholder="开始时间" end-placeholder="结束时间"></el-date-picker>
                        </el-form-item>
                        <el-form-item label="">
                            <el-button type="primary" @click="queryFn">查询</el-button>
                            <el-button @click="queryResetFn">重置</el-button>
                        </el-form-item>
                    </el-form>
                    <el-table :data="refund_order.list" stripe border style="width: 100%">
                        <el-table-column header-align="center" align="center" v-for="(item, index) of refund_order.column_key" :key="index" :prop="item.prop" :label="item.label" :width="item.width ? item.width : null">
                            <template #default="scope">
                                <div v-if="item.prop == 'control'">
                                    <el-button class="global-mb-10" size="mini" type="primary" @click="openRefundDetailPopup(scope.row)" v-allow="['view']">查看详情</el-button>
                                    <el-button class="global-mb-10" v-if="scope.row.status == 1" size="mini" type="primary" @click="showRefundAgreeFun(scope.row)" v-allow="['refundResolve']">退换货通过</el-button>
                                    <el-button class="global-mb-10" v-if="scope.row.status == 1" size="mini" type="danger" @click="revokeAuditStatus(scope.row,3)" v-allow="['refundReject']">退换货拒绝</el-button>
                                    <el-button class="global-mb-10" v-if="scope.row.status == 4" size="mini" type="primary" @click="cashConfirm(scope.row,1)">退货现金确认</el-button>
                                    <el-button class="global-mb-10" v-if="scope.row.status == 4" size="mini" type="danger" @click="cashConfirm(scope.row,2)">退货现金拒绝</el-button>
                                    <el-button size="mini" type="success" @click="renewPrintIndex(scope.row,'return')" v-allow="['thdPrint']">退换单重打印</el-button>
                                </div>
                                <div v-else-if="item.prop == 'member_name'">
                                    <div>{{scope.row.member_name}}</div>
                                    <div>{{scope.row.member_phone}}</div>
                                </div>
                                <div v-else-if="item.prop == 'type'">
                                    <div v-if="scope.row.type === 1" class="type1">退品</div>
                                    <div v-else-if="scope.row.type === 2" class="type2">换品</div>
                                    <div v-else>--</div>
                                </div>
                                <div v-else-if="item.prop == 'produce_status'">
                                    <div v-if="scope.row.produce_status === 1" class="type1">未出品</div>
                                    <div v-else-if="scope.row.produce_status === 2" class="type2">已出品</div>
                                    <div v-else>--</div>
                                </div>
                                <div v-else-if="item.prop == 'status'">
                                    <el-tag type="info" v-if="scope.row.status === 1">待审核</el-tag>
                                    <el-tag type="success" v-else-if=" scope.row.status === 2">通过</el-tag>
                                    <el-tag type="danger" v-else-if="scope.row.status === 3">拒绝</el-tag>
                                    <el-tag type="danger" v-else-if="scope.row.status === 4">现金待确认</el-tag>
                                    <div v-else>--</div>
                                </div>
                                <div v-else>{{ scope.row[item.prop] }}</div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <!-- 退换单 end -->

                <!-- 赠送单 start -->
                <div v-if="tab_config.tab_id == 50">
                    <el-form :model="search_config.form" :rules="search_config.rules" ref="ruleForm" label-width="100px" class="start-desk-box" :inline="true">
                        <el-form-item label="赠送单号" prop="give_bill">
                            <el-input class="input" v-model="search_config.form.give_bill" placeholder="请输入赠送单号" @keyup.enter="queryFn"></el-input>
                        </el-form-item>
                        <el-form-item label="台位区域" prop="region_name">
                            <el-input class="input" v-model="search_config.form.region_name" placeholder="请输入台位区域" @keyup.enter="queryFn"></el-input>
                        </el-form-item>
                        <el-form-item label="台位号" prop="room_name">
                            <el-input class="input" v-model="search_config.form.room_name" placeholder="请输入台位名称" @keyup.enter="queryFn"></el-input>
                        </el-form-item>
                        <el-form-item label="会员信息" prop="member">
                            <el-input class="input" v-model="search_config.form.member" placeholder="请输入会员信息/手机号" @keyup.enter="queryFn"></el-input>
                        </el-form-item>
                        <el-form-item label="时间筛选" prop="times">
                            <el-select v-model="search_config.form.type" placeholder="请选择" class="order-type" @change="typeChange">
                                <el-option v-for="(item,index) of search_config.type_options" :value="item.id" :label="item.label" :key="index"></el-option>
                            </el-select>
                            <el-date-picker :clearable="false" v-if="search_config.form.type == 1" v-model="search_config.form.times" type="datetimerange" start-placeholder="开始时间" end-placeholder="结束时间"></el-date-picker>
                            <el-date-picker :clearable="false" v-else v-model="search_config.form.times" type="daterange" start-placeholder="开始时间" end-placeholder="结束时间"></el-date-picker>
                        </el-form-item>
                        <el-form-item label="">
                            <el-button type="primary" @click="queryFn">查询</el-button>
                            <el-button @click="queryResetFn">重置</el-button>
                            <el-button type="primary" plain @click="giveOrderExportFun">订单导出</el-button>
                        </el-form-item>
                    </el-form>
                    <el-table :data="gift_order.list" stripe border style="width: 100%">
                        <el-table-column header-align="center" align="center" v-for="(item, index) of gift_order.column_key" :key="index" :prop="item.prop" :label="item.label" :width="item.width ? item.width : null">
                            <template #default="scope">
                                <div v-if="item.prop == 'control'">
                                    <el-button size="mini" type="primary" @click="opengiveGiftDetailPopup(scope.row)">查看详情</el-button>
                                    <el-button size="mini" v-if="scope.row.status == 3" type="primary" @click="auditCancelOrder(scope.row,'agree')">通过审核</el-button>
                                    <el-button size="mini" v-if="scope.row.status == 3" type="danger" @click="auditCancelOrder(scope.row,'cancel')">拒绝取消</el-button>
                                    <!-- 旧打印 暂时废弃 -->
                                    <!-- <el-button size="mini" type="primary" @click="printGiveGift(scope.row)">打印</el-button> -->
                                    <!-- <el-button size="mini" type="success" @click="renewPrintIndex(scope.row,'give')" v-allow="['zsdPrint']">赠送单重打印</el-button> -->
                                    <el-button size="mini" type="success" @click="printGiveGift(scope.row)" v-allow="['zsdPrint']">赠送单重打印</el-button>
                                </div>
                                <div v-else-if="item.prop == 'user_name'">
                                    <div>{{scope.row.user_name}}</div>
                                    <div>{{scope.row.phone}}</div>
                                </div>
                                <div v-else-if="item.prop == 'status_name'">
                                    <el-tag type="primary">{{scope.row[item.prop]}}</el-tag>
                                </div>
                                <div v-else>{{ scope.row[item.prop] }}</div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <!-- 赠送单 end -->

                <!-- 开台记录列表 start -->
                <div v-if="tab_config.tab_id == 48">
                    <el-form :model="search_config.form" :rules="search_config.rules" ref="ruleForm" label-width="100px" class="start-desk-box" :inline="true">
                        <el-form-item label="开台编号" prop="open_room_bill">
                            <el-input class="input" v-model="search_config.form.open_room_bill" placeholder="请输入开台编号" @keyup.enter="queryFn"></el-input>
                        </el-form-item>
                        <el-form-item label="订台人" prop="staff_name">
                            <el-input class="input" v-model="search_config.form.staff_name" placeholder="请输入订台人姓名" @keyup.enter="queryFn"></el-input>
                        </el-form-item>
                        <el-form-item label="台位号" prop="room_name">
                            <el-input class="input" v-model="search_config.form.room_name" placeholder="请输入台位号" @keyup.enter="queryFn"></el-input>
                        </el-form-item>
                        <el-form-item label="会员名称" prop="user_name">
                            <el-input class="input" v-model="search_config.form.user_name" placeholder="请输入会员名称" @keyup.enter="queryFn"></el-input>
                        </el-form-item>
                        <el-form-item label="会员手机号" prop="phone">
                            <el-input class="input" v-model="search_config.form.phone" placeholder="请输入会员手机号" @keyup.enter="queryFn"></el-input>
                        </el-form-item>
                        <el-form-item label="交易时间" prop="times">
                            <el-select v-model="search_config.form.type" placeholder="请选择" class="order-type" @change="typeChange">
                                <el-option v-for="(item,index) of search_config.type_options" :value="item.id" :label="item.label" :key="index"></el-option>
                            </el-select>
                            <el-date-picker :clearable="false" v-if="search_config.form.type == 1" v-model="search_config.form.times" type="datetimerange" start-placeholder="开始时间" end-placeholder="结束时间"></el-date-picker>
                            <el-date-picker :clearable="false" v-else v-model="search_config.form.times" type="daterange" start-placeholder="开始时间" end-placeholder="结束时间"></el-date-picker>
                        </el-form-item>
                        <el-form-item label="">
                            <el-button type="primary" @click="queryFn">查询</el-button>
                            <el-button @click="queryResetFn">重置</el-button>
                        </el-form-item>
                    </el-form>
                    <el-table :data="open_room.list" stripe border style="width: 100%">
                        <el-table-column header-align="center" align="center" v-for="(item, index) of open_room.column_key" :key="index" :prop="item.prop" :label="item.label" :width="item.width ? item.width : null">
                            <template #default="scope">
                                <div v-if="item.prop == 'control'">
                                    <el-button size="mini" type="primary" @click="changeMember(scope.row)">更换会员</el-button>
                                    <el-button size="mini" type="primary" @click="changeStaff(scope.row)">更换订台人</el-button>
                                    <el-button size="mini" type="success" @click="printOpenRoom(scope.row)">打印</el-button>
                                </div>
                                <div v-else-if="item.prop == 'open_room_bill'">
                                    <span class="global-cursor-pointer" @click="toOrderDetailList(scope.row)">{{scope.row[item.prop]}}</span>
                                </div>
                                <div v-else-if="item.prop == 'status'">
                                    <span v-if="scope.row[item.prop] == 1">空台</span>
                                    <span v-else-if="scope.row[item.prop] == 2">开台中</span>
                                    <span v-else-if="scope.row[item.prop] == 3">订台</span>
                                    <span v-else-if="scope.row[item.prop] == 4">锁台</span>
                                    <span v-else-if="scope.row[item.prop] == 5">后结</span>
                                </div>
                                <div v-else>{{ scope.row[item.prop] }}</div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <!-- 开台记录列表 end -->

                <!-- 挂账台位列表 start -->
                <div v-if="tab_config.tab_id == 47">
                    <el-form :model="search_config.form" :rules="search_config.rules" ref="ruleForm" label-width="100px" class="start-desk-box" :inline="true">
                        <el-form-item label="开台编号" prop="open_room_bill">
                            <el-input class="input" v-model="search_config.form.open_room_bill" placeholder="请输入开台编号" @keyup.enter="queryFn"></el-input>
                        </el-form-item>
                        <el-form-item label="订台人" prop="staff_name">
                            <el-input class="input" v-model="search_config.form.staff_name" placeholder="请输入订台人姓名" @keyup.enter="queryFn"></el-input>
                        </el-form-item>
                        <el-form-item label="台位号" prop="room_name">
                            <el-input class="input" v-model="search_config.form.room_name" placeholder="请输入台位号" @keyup.enter="queryFn"></el-input>
                        </el-form-item>
                        <el-form-item label="会员名称" prop="user_name">
                            <el-input class="input" v-model="search_config.form.user_name" placeholder="请输入会员姓名" @keyup.enter="queryFn"></el-input>
                        </el-form-item>
                        <el-form-item label="会员手机号" prop="phone">
                            <el-input class="input" v-model="search_config.form.phone" placeholder="请输入会员手机号" @keyup.enter="queryFn"></el-input>
                        </el-form-item>
                        <el-form-item label="清账状态" prop="credit_status">
                            <el-select v-model="search_config.form.credit_status" placeholder="请选择" class="order-type">
                                <el-option :value="0" label="全部" :key="index"></el-option>
                                <el-option :value="1" label="未结清" :key="index"></el-option>
                                <el-option :value="2" label="已结清" :key="index"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="时间筛选" prop="times">
                            <el-select v-model="search_config.form.type" placeholder="请选择" class="order-type" @change="typeChange">
                                <el-option v-for="(item,index) of search_config.type_options" :value="item.id" :label="item.label" :key="index"></el-option>
                            </el-select>
                            <el-date-picker :clearable="false" v-if="search_config.form.type == 1" v-model="search_config.form.times" type="datetimerange" start-placeholder="开始时间" end-placeholder="结束时间"></el-date-picker>
                            <el-date-picker :clearable="false" v-else v-model="search_config.form.times" type="daterange" start-placeholder="开始时间" end-placeholder="结束时间"></el-date-picker>
                        </el-form-item>
                        <el-form-item label="">
                            <el-button type="primary" @click="queryFn">查询</el-button>
                            <el-button @click="queryResetFn">重置</el-button>
                        </el-form-item>
                    </el-form>
                    <el-table :data="clean_room.list" stripe border style="width: 100%">
                        <el-table-column header-align="center" align="center" v-for="(item, index) of clean_room.column_key" :key="index" :prop="item.prop" :label="item.label" :width="item.width ? item.width : null">
                            <template #default="scope">
                                <div v-if="item.prop == 'control'">
                                    <el-button size="mini" type="primary" @click="openCleanOrderDialog(scope.row)">查看挂账</el-button>
                                </div>
                                <div v-else-if="item.prop == 'credit_status'">
                                    <el-tag type="info" v-if="scope.row[item.prop] == 1">未结清</el-tag>
                                    <el-tag type="success" v-else-if="scope.row[item.prop] === 2">已结清</el-tag>
                                </div>
                                <div v-else>{{ scope.row[item.prop] }}</div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <!-- 挂账台位列表 end -->

                <!-- 充值列表 start -->
                <div v-if="tab_config.tab_id == 49">
                    <el-form :model="search_config.form" :rules="search_config.rules" ref="ruleForm" label-width="100px" class="start-desk-box" :inline="true">
                        <el-form-item label="充值单号" prop="recharge_order_no">
                            <el-input class="input" v-model="search_config.form.recharge_order_no" placeholder="请输入充值单号" @keyup.enter="queryFn"></el-input>
                        </el-form-item>
                        <el-form-item label="交易单号" prop="transaction_no">
                            <el-input class="input" v-model="search_config.form.transaction_no" placeholder="请输入交易单号" @keyup.enter="queryFn"></el-input>
                        </el-form-item>
                        <el-form-item label="会员信息" prop="member">
                            <el-input class="input" v-model="search_config.form.member" placeholder="请输入会员名称和手机号" @keyup.enter="queryFn"></el-input>
                        </el-form-item>
                        <el-form-item label="支付方式" prop="pay_way_id">
                            <el-select class="select-box" v-model="search_config.form.pay_way_id" placeholder="请选择">
                                <el-option
                                    v-for="item of payment_config.pay_list"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="状态" prop="pay_status">
                            <el-select class="select-box" v-model="search_config.form.pay_status" placeholder="请选择">
                                <el-option
                                    v-for="item of search_config.pay_status_options"
                                    :key="item.id"
                                    :label="item.label"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="">
                            <el-button type="primary" @click="queryFn">查询</el-button>
                            <el-button @click="queryResetFn">重置</el-button>
                        </el-form-item>
                    </el-form>
                    <el-table :data="purcharse_cash_audit_order.list" stripe border style="width: 100%">
                        <el-table-column header-align="center" align="center" v-for="(item, index) of purcharse_cash_audit_order.column_key" :key="index" :prop="item.prop" :label="item.label" :width="item.width ? item.width : null">
                            <template #default="scope">
                                <div v-if="item.prop == 'control'">
                                    <div v-if="scope.row.pay_status == 3">
                                        <el-button class="global-mb-10 global-mt-10" size="mini" type="primary" @click="rechargeCashConfirm(scope.row,2)">通过</el-button>
                                        <el-button class="global-mb-10 global-mt-10" size="mini" type="danger" @click="rechargeCashConfirm(scope.row,4)">拒绝</el-button>
                                    </div>
                                    <el-button class="global-mb-10 global-mt-10" size="mini" type="success" @click="renewPrintIndex(scope.row,'recharge')" v-allow="['czdPrint']">充值单重打印</el-button>
                                </div>
                                <div v-else-if="item.prop == 'pay_status'">
                                    <el-tag type="info" v-if="scope.row.pay_status == 1">未成功</el-tag>
                                    <el-tag type="success" v-else-if="scope.row.pay_status == 2">已成功</el-tag>
                                    <el-tag type="info" v-else-if="scope.row.pay_status == 3">待确认</el-tag>
                                    <el-tag type="danger" v-else-if="scope.row.pay_status == 4">不通过</el-tag>
                                </div>
                                <div v-else>{{ scope.row[item.prop] }}</div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <!-- 充值列表 end -->

            </div>

            <div class="pagination-box">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pagination.page"
                    :page-sizes="pagination.page_sizes"
                    :page-size="pagination.page_size"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pagination.total"
                >
                </el-pagination>
            </div>
        </div>

        <!-- 查看详情 start -->
        <el-dialog
            class="view-detail-dialog"
            :title="detail_popup_config.title"
            v-model="detail_popup_config.is_show"
            width="1000px"
            :show-close="false"
            :close-on-click-modal="false"
            :close-on-press-escape="false">
            <div>
                <div>
                    <div class="dialog-title">订单信息</div>
                    <div class="member-info">
                        <div class="dialog-info-item">
                            <span>订单号：{{ popup_config.order_number }}</span>
                            <span>订单时间：{{ data }}</span>
                        </div>
                        <div class="dialog-info-item">
                            <span>台位区域：{{popup_config.station_region_name}}</span>
                            <span>台位号：{{popup_config.station_name}}</span>
                        </div>
                        <div class="dialog-info-item">
                            <span>服务员：{{ popup_config.waiter_name }}</span>
                            <span>订单金额：{{ popup_config.total_money }}</span>
                        </div>
                        <div class="dialog-info-item">
                            <span>积分抵消：{{popup_config.integral_money}}</span>
                            <span>实付金额：{{popup_config.actual_amount}}</span>
                        </div>
                        <div class="dialog-info-item">
                            <span>下单备注：{{popup_config.remarks}}</span>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="dialog-title">会员信息</div>
                    <div class="member-info">
                        <div class="dialog-info-item">
                            <span>会员姓名：{{ popup_config.member_name }}</span>
                            <span>手机号：{{ popup_config.member_phone }}</span>
                        </div>
                        <div class="dialog-info-item">
                            <span>订台人：{{ popup_config.sales_name }}</span>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="dialog-title">商品信息</div>
                    <el-table
                        :data="popup_config.commodity"
                        stripe
                        border
                        style="width: 100%">
                        <el-table-column type="expand">
                            <template #default="scope">
                                <ul class="collapse-item">
                                    <li v-for="(package_item,package_index) of scope.row.package" :key="package_index">
                                        <div class="goods-img">
                                            <img v-if="package_item.product_pic" :src="package_item.product_pic">
                                            <img v-else :src="default_img">
                                            
                                        </div>
                                        <div class="goods-info">
                                            <span class="name">{{package_item.product_name}}</span>
                                            <span class="num">*{{package_item.product_num}}</span>
                                        </div>
                                    </li>
                                </ul>
                            </template>
                        </el-table-column>
                        <el-table-column header-align="center" align="center" v-for="(item, index) of detail_popup_config.column_key" :key="index" :prop="item.prop" :label="item.label" :width="item.width ? item.width : null">
                            <template #default="scope">
                                <div v-if="item.prop == 'residue'">
                                    <el-progress :percentage="scope.row[item.prop]"></el-progress>
                                </div>
                                <div v-else-if="item.prop == 'product_name'">
                                    <span>{{scope.row[item.prop]}}</span>
                                    <div class="global-inline-block">
                                        <span class="refund_label" v-if="scope.row.product_type == 1 && scope.row.refund_status == 1">正在退货{{scope.row.return_change_num}}{{scope.row.product_unit_name}}</span>
                                        <span class="refund_label" v-if="scope.row.product_type == 2 && scope.row.refund_status == 1">正在换货{{scope.row.return_change_num}}{{scope.row.product_unit_name}}</span>
                                        <span class="refund_label" v-if="scope.row.return_num">已退{{scope.row.return_num}}{{scope.row.product_unit_name}}</span>
                                        <span class="refund_label" v-if="scope.row.change_num">已换{{scope.row.change_num}}{{scope.row.product_unit_name}}</span>
                                        <span class="refund_label huan" v-if="scope.row.product_type == 3">换</span>
                                    </div>
                                </div>
                                <div v-else>{{scope.row[item.prop]}}</div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="dialog-bottom-info">
                    <div class="info">
                        <span>￥{{ total_config }}</span>
                        <span class="text">商品合计</span>
                    </div>
                    <div class="info">
                        <span class="active">￥{{ popup_config.discount_money }}</span>
                        <span class="text">会员折扣</span>
                    </div>
                    <div class="info">
                        <span class="active">￥{{ popup_config.integral_money }}</span>
                        <span class="text">积分抵扣</span>
                    </div>
                    <div class="info big-text">
                        <span>￥{{ popup_config.actual_amount }}</span>
                        <span class="text big-text">实际支付</span>
                    </div>
                </div>
            </div>
            <div v-if="payment_config.type == 1">
                <div class="dialog-title">支付方式</div>
                <payComponent ref="pay" :pay_moeny="payment_config.amount_money" @payChange="payChange"/>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button
                        class="input"
                        type="primary"
                        @click="paymentFun"
                        v-if="payment_config.type == 1"
                        v-allow="['pay']"
                        >确认支付</el-button
                    >
                    <el-button @click="cancelFun">关闭</el-button>
                </span>
            </template>
        </el-dialog>
        <!-- 查看详情 end -->

        <!-- 退换单详情 start -->
        <el-dialog class="view-detail-dialog" :title="refund_order_popup_config.title" v-model="refund_order_popup_config.is_show" width="1000px" :before-close="handleClose" :showClose="false">
            <div>
                <div>
                    <div class="dialog-title">订单信息</div>
                    <div class="member-info">
                        <div class="dialog-info-item">
                            <span>退换单号：{{refund_order_popup_config.data.return_order_number}}</span>
                            <span>申请时间：{{refund_order_popup_config.data.created_at}}</span>
                        </div>
                        <div class="dialog-info-item">
                            <div>
                                <span>订单号：{{refund_order_popup_config.data.order_number}}</span>
                            </div>
                            <span>操作员：{{refund_order_popup_config.data.operator_name}}</span>
                        </div>
                        <div class="dialog-info-item">
                            <span>台位区域：{{refund_order_popup_config.data.station_name}}（{{refund_order_popup_config.data.station_region_name}}）</span>
                            <div>
                                <span>出品情况：</span>
                                <span class="red" v-if="refund_order_popup_config.data.produce_status === 1">未出品</span>
                                <span class="red" v-else-if="refund_order_popup_config.data.produce_status === 2">已出品</span>
                                <span class="red" v-else>--</span>
                            </div>
                        </div>
                        <div class="dialog-info-item">
                            <div>
                                <span>退款金额：</span>
                                <span class="red">￥{{refund_order_popup_config.data.refund_money}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="dialog-title">商品信息</div>
                    <div v-if="refund_order_popup_config.origin_list.length > 0">
                        <el-tag class="dialog-goods-tag" key="原商品" type="success" effect="dark">原商品</el-tag>
                        <goodsListTableComponent :list="refund_order_popup_config.origin_list" :keys="refund_order_popup_config.column_key"/>
                    </div>
                    <div v-if="refund_order_popup_config.quit_list.length > 0">
                        <el-tag class="dialog-goods-tag" key="退商品" type="danger" effect="dark">退商品</el-tag>
                        <goodsListTableComponent :list="refund_order_popup_config.quit_list" :keys="refund_order_popup_config.column_key"/>
                    </div>
                    <div v-if="refund_order_popup_config.exchange_list.length > 0">
                        <el-tag class="dialog-goods-tag" key="换商品" type="danger" effect="dark">换商品</el-tag>
                        <goodsListTableComponent :list="refund_order_popup_config.exchange_list" :keys="refund_order_popup_config.column_key"/>
                    </div>
                </div>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="closeRefundOrderPopupConfig">取消</el-button>
                </span>
            </template>
        </el-dialog>
        <!-- 退换单详情 end -->

        <el-dialog :title="refund_agree_config.title" v-model="refund_agree_config.is_show" width="200px" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false">
            <div>
                <el-select v-model="refund_agree_config.form.type" placeholder="请选择">
                    <el-option :key="1" label="在线支付原路退回" :value="1"></el-option>
                    <el-option :key="2" label="退回会员余额" :value="2"></el-option>
                    <el-option :key="3" label="现金退回" :value="3"></el-option>
                    <el-option :key="6" label="赠送金退回" :value="6"></el-option>
                </el-select>
            </div>
            <template #footer>
                <span class="dialog-footer">
                <el-button @click="refundAgreeCancel">取 消</el-button>
                <el-button type="primary" @click="refundAgreeOk">确 定</el-button>
                </span>
            </template>
        </el-dialog>

        <!-- 赠送单详情 start -->
        <el-dialog class="view-detail-dialog" :title="give_gift_popup_config.title" v-model="give_gift_popup_config.is_show" width="1000px" :showClose="false">
            <div>
                <div class="dialog-title">订单信息</div>
                <div class="member-info">
                    <div class="dialog-info-item">
                        <span>赠送单号：{{give_gift_popup_config.data.give_bill}}</span>
                        <span>赠送时间：{{give_gift_popup_config.data.created_at}}</span>
                    </div>
                    <div class="dialog-info-item">
                        <div>
                            <span>赠送人：{{give_gift_popup_config.data.giver_name}}</span>
                        </div>
                        <span>赠送对象：{{give_gift_popup_config.data.user_name}}</span>
                    </div>
                    <div class="dialog-info-item">
                        <div>台位区域：{{give_gift_popup_config.data.region_name}}</div>
                        <div>服务员：{{give_gift_popup_config.data.waiter_name}}</div>
                    </div>
                    <div class="dialog-info-item">
                        <div>状态：{{give_gift_popup_config.data.status_name}}</div>
                    </div>
                    <div>
                        <el-table :data="give_gift_popup_config.data.goods_arr" stripe border style="width: 100%">
                            <el-table-column header-align="center" align="center" v-for="(item, index) of give_gift_popup_config.column_key" :key="index" :prop="item.prop" :label="item.label" :width="item.width ? item.width : null">
                                <template #default="scope">
                                    <div>{{scope.row[item.prop]}}</div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="closeGiveGiftPopupConfig">关闭</el-button>
                </span>
            </template>
        </el-dialog>
        <!-- 赠送单详情 end -->

        <!-- 挂账列表 start -->
        <cleanOrderDialogComponent v-model:is_show="clean_order_dialog.is_show" :open_room_id="clean_order_dialog.item.id"></cleanOrderDialogComponent>
        <!-- 挂账列表 end -->

        <!-- 选择会员 start -->
        <memberDialogComponent v-model:is_show="member_dialog_config.is_show" @agree="memberAgreeFun"></memberDialogComponent>
        <!-- 选择会员 end -->

        <!-- 选择营销 start -->
        <staffDialogComponent v-model:is_show="staff_dialog_config.is_show" @agree="staffAgreeFun"></staffDialogComponent>
        <!-- 选择营销 end -->

        <!-- 选择台位 start -->
        <checkDeskListComponent v-model:is_show="desk_dialog_config.is_show" @agree="deskAgreeFun"></checkDeskListComponent>
        <!-- 选择台位 end -->

        <!-- 开台记录 start -->
        <recoveryDialogComponent v-model:is_show="recovery_config.is_show" :room_id="recovery_config.room_id" @agree="recoveryAgreeFun"></recoveryDialogComponent>
        <!-- 开台记录 end -->



    </div>
</template>
<script>
import {default_img} from '@/config'
import { pubDeskApi,cashierApi } from "@/api";
import { compareTime, customFormatFullTime, toDecimal } from "@/assets/js/utils";
import calculation from "@/assets/js/calculation";
import goodsListTableComponent from "./components/goodsListTable.vue";
import {orderMixins,merchantInfoMixins,defatulDateMixins} from '@/mixins'
import staffDialogComponent from '@/components/staffDialog'
import memberDialogComponent from '@/components/memberDialog'
import checkDeskListComponent from '@/components/checkDeskList'
import recoveryDialogComponent from '@/components/recoveryDialog'
import cleanOrderDialogComponent from '@/components/cleanOrderDialog'
import tableComponent from '@/components/table'
import payComponent from '@/components/pay'
export default {
    name: "pub-desk-page",
    components: {
        goodsListTableComponent,
        staffDialogComponent,
        memberDialogComponent,
        checkDeskListComponent,
        recoveryDialogComponent,
        cleanOrderDialogComponent,
        tableComponent,
        payComponent
    },
    mixins:[orderMixins,merchantInfoMixins,defatulDateMixins],
    data() {
        return {
            default_img:default_img,
            // 开台记录列表
            recovery_config:{
                is_show:false,
                room_id:null
            },
            // 台位列表
            desk_dialog_config:{
                is_show:false,
                item:null
            },
            member_dialog_config:{
                is_show:false,
                type:0, //1选择会员 2更换会员
            },
            staff_dialog_config:{
                is_show:false,
                item:null
            },
            refund_agree_config:{
                is_show:false,
                title:'确认',
                form:{
                    type:'',
                    item:''
                }
            },
            search_config:{
                rules:{},
                form:{
                    type:2, //1系统时间 2营业日
                    order_number:'',
                    station_name:'',
                    refund_type:'', //0全部 1退品 2换品
                    return_order_number:'',
                    member:'',
                    sales_name:'',
                    waiter_name:'',
                    times:'',
                    status:'',
                    posterior_knot_status:'',
                    pay_no:'',
                    station_region_name:'',
                    recharge_order_no:'',
                    transaction_no:'',
                    pay_status:0,
                    payment_method:'',
                    pay_way_id:'',
                    open_room_bill:'',
                    staff_name:'',
                    room_name:'',
                    user_name:'',
                    phone:'',
                    give_bill:'',
                    region_name:'',
                    credit_status:0,
                    bill_type:0
                },
                bill_type_options:[
                    {   
                        id:0,
                        name:'全部'      
                    },
                    {   
                        id:1,
                        name:'订单'      
                    },
                    {   
                        id:5,
                        name:'挂账单'      
                    },
                    {   
                        id:6,
                        name:'清账单'      
                    },
                ],
                refund_type_options:[
                    {
                        id:1,
                        label:'退品'
                    },
                    {
                        id:2,
                        label:'换品'
                    }
                ],
                type_options:[
                    {
                        id:1,
                        label:'系统时间'
                    },
                    {
                        id:2,
                        label:'营业日'
                    }
                ],
                pay_status_options:[
                    {
                        id:0,
                        label:'全部'
                    },
                    {
                        id:1,
                        label:'未成功'
                    },
                    {
                        id:2,
                        label:'已成功'
                    },
                    {
                        id:3,
                        label:'现金待确认'
                    },
                    {
                        id:4,
                        label:'不通过'
                    }
                ],
                status_options:[
                    {
                        id:1,
                        label:'未结算'
                    },
                    {
                        id:2,
                        label:'已支付'
                    },
                    {
                        id:3,
                        label:'待确认'
                    },
                    {
                        id:4,
                        label:'已取消'
                    }
                ],
                posterior_knot_status_options:[
                    {
                        id:1,
                        label:'后结待审核'
                    },
                    {
                        id:2,
                        label:'后结审核通过'
                    },
                    {
                        id:3,
                        label:'后结已拒绝'
                    },
                    {
                        id:4,
                        label:'取消订单待审核'
                    },
                    {
                        id:5,
                        label:'订单已取消'
                    },
                    {
                        id:6,
                        label:'拒绝取消此订单'
                    },
                ]
            },
            give_gift_popup_config:{
                title: "赠送单详情",
                is_show: false,
                data: {},
                column_key: [
                    {
                        prop: "goods_name",
                        label: "商品名称",
                    },
                    {
                        prop: "goods_num",
                        label: "商品数量",
                    },
                    {
                        prop: "goods_unit_name",
                        label: "商品单位",
                    },
                ]
            },
            refund_order_popup_config: {
                title: "退换详情",
                is_show: false,
                data: {},
                column_key: [
                    {
                        prop: "product_pic",
                        label: "商品图",
                    },
                    {
                        prop: "product_name",
                        label: "商品名称",
                    },
                    {
                        prop: "returns_product_num",
                        label: "数量",
                    },
                    {
                        prop: "returns_product_price",
                        label: "商品金额",
                    },
                ],
                // 原商品
                origin_list: [],
                // 退商品
                quit_list: [],
                // 换商品
                exchange_list: [],
            },
            /**
             * 挂账列表
             */
            clean_order_dialog:{
                title:'挂账列表',
                is_show:false,
                item:{}
            },
            // 开台记录列表
            open_room: {
                column_key: [
                    {
                        prop: "open_room_bill",
                        label: "开台编号",
                    },
                    {
                        prop: "open_room_time",
                        label: "开台时间",
                    },
                    {
                        prop: "room_name",
                        label: "台位号",
                    },
                    {
                        prop: "staff_name",
                        label: "订台人",
                    },
                    {
                        prop: "user_name",
                        label: "会员名称",
                    },
                    {
                        prop: "phone",
                        label: "手机号",
                    },
                    {
                        prop: "open_room_end_time",
                        label: "清台时间"
                    },
                    {
                        prop: "control",
                        label: "操作",
                        width: 300,
                    },
                ],
                list: [],
            },
            clean_room:{
                column_key: [
                    {
                        prop: "open_room_bill",
                        label: "开台编号",
                    },
                    {
                        prop: "open_room_time",
                        label: "开台时间",
                    },
                    {
                        prop: "room_name",
                        label: "台位号",
                    },
                    {
                        prop: "staff_name",
                        label: "订台人",
                    },
                    {
                        prop: "user_name",
                        label: "会员名称",
                    },
                    {
                        prop: "phone",
                        label: "手机号",
                    },
                    {
                        prop: "open_room_end_time",
                        label: "清台时间"
                    },
                    {
                        prop: "credit_money",
                        label: "挂账金额"
                    },
                    {
                        prop: "clear_money",
                        label: "已支付金额"
                    },
                    {
                        prop: "credit_status",
                        label: "清账状态"
                    },
                    {
                        prop: "control",
                        label: "操作",
                        width: 300,
                    },
                ],
                list: [],
            },
            // 开台记录列表
            open_room: {
                column_key: [
                    {
                        prop: "open_room_bill",
                        label: "开台编号",
                    },
                    {
                        prop: "open_room_time",
                        label: "开台时间",
                    },
                    {
                        prop: "room_name",
                        label: "台位号",
                    },
                    {
                        prop: "staff_name",
                        label: "订台人",
                    },
                    {
                        prop: "user_name",
                        label: "会员名称",
                    },
                    {
                        prop: "phone",
                        label: "手机号",
                    },
                    {
                        prop: "open_room_end_time",
                        label: "清台时间"
                    },
                    {
                        prop: "control",
                        label: "操作",
                        width: 300,
                    },
                ],
                list: [],
            },
            detail_popup_config: {
                title: "交易详情",
                is_show: false,
                column_key: [
                    {
                        prop: "product_name",
                        label: "商品名称",
                    },
                    {
                        prop: "product_num",
                        label: "数量",
                    },
                    {
                        prop: "unit_price",
                        label: "商品单价",
                    },
                    {
                        prop: "product_price",
                        label: "商品总价",
                    },
                ],
                list: [],
            },
            popup_config: {},
            total_config: 0,
            actual_config: 0,
            data: "",
            pagination: {
                page: 1,
                page_size: 10,
                total: 0,
                page_sizes: [10, 20, 30],
            },
            tab_config: {
                tab_id: "3",
                list: [
                    {
                        id: "3",
                        label: "待确认",
                    },
                    {
                        id: "48",
                        label: "开台记录",
                    },
                    {
                        id: "0",
                        label: "订单明细",
                    },
                    {
                        id: "0-1",
                        label: "支付明细",
                    },
                    {
                        id: "4",
                        label: "退换单",
                    },
                    {
                        id: "50",
                        label: "赠送单",
                    },
                    {
                        id: "49",
                        label: "充值列表",
                    },
                    {
                        id: "47",
                        label: "挂账列表",
                    },
                ],
            },
            gift_order: {
                column_key: [
                    {
                        prop: "give_bill",
                        label: "赠送单号",
                    },
                    {
                        prop: "giver_name",
                        label: "赠送人",
                    },
                    {
                        prop: "user_name",
                        label: "赠送对象",
                    },
                    {
                        prop: "created_at",
                        label: "赠送时间",
                        width:160
                    },
                    {
                        prop: "waiter_name",
                        label: "服务员",
                    },
                    {
                        prop: "room_name",
                        label: "台位号",
                    },
                    {
                        prop: "region_name",
                        label: "台位区域",
                    },
                    {
                        prop: "status_name",
                        label: "状态",
                    },
                    {
                        prop: "control",
                        label: "操作",
                        width: 400,
                    },
                ],
                list: [],
            },
            market_table: {},
            trade_order: {
                column_key: [
                    {
                        prop: "order_number",
                        label: "订单号",
                    },
                    {
                        prop: "open_room_bill",
                        label: "开台编号",
                    },
                    {
                        prop: "created_at",
                        label: "订单时间",
                    },
                    {
                        prop: "station_region_name",
                        label: "台位区域",
                    },
                    {
                        prop: "station_name",
                        label: "台位号",
                    },
                    {
                        prop: "member_name",
                        label: "会员信息",
                    },
                    {
                        prop: "total_money",
                        label: "订单总额",
                    },
                    {
                        prop: "actual_amount",
                        label: "实收金额",
                    },
                    {
                        prop: "integral_money",
                        label: "积分抵消",
                    },
                    {
                        prop: "payment_method",
                        label: "支付方式",
                    },
                    {
                        prop: "waiter_name",
                        label: "下单人员",
                    },
                    {
                        prop: "posterior_knot",
                        label: "后结",
                    },
                    {
                        // prop: "status",
                        prop: "status_name",
                        label: "状态",
                        width:200
                    },
                    {
                        prop: "control",
                        label: "操作",
                        width: 400,
                    },
                ],
                list: [],
            },
            refund_order: {
                column_key: [
                    {
                        prop: "return_order_number",
                        label: "退换单号",
                    },
                    {
                        prop: "order_number",
                        label: "订单号",
                    },
                    {
                        prop: "created_at",
                        label: "申请时间",
                    },
                    {
                        prop: "station_region_name",
                        label: "台位区域",
                    },
                    {
                        prop: "station_name",
                        label: "台位号",
                    },
                    {
                        prop: "member_name",
                        label: "会员信息",
                    },
                    {
                        prop: "payment_method_name",
                        label: "支付方式",
                    },
                    {
                        prop: "return_pay_type_name",
                        label: "退回会员赠送金",
                    },
                    {
                        prop: "operator_name",
                        label: "操作员",
                    },
                    {
                        prop: "type",
                        label: "类型",
                    },
                    {
                        prop: "refund_money",
                        label: "退款金额",
                    },
                    // {
                    //     prop: "produce_status",
                    //     label: "出品情况",
                    // },
                    {
                        prop: "status",
                        label: "状态",
                    },
                    {
                        prop: "control",
                        label: "操作",
                        width: 300,
                    },
                ],
                list: [],
            },
            pay_order: {
                column_key: [
                    {
                        prop: "order_number",
                        label: "订单号",
                    },
                    {
                        prop: "open_room_bill",
                        label: "开台编号",
                    },
                    {
                        prop: "pay_no",
                        label: "支付单号",
                    },
                    {
                        prop: "created_at",
                        label: "交易时间",
                    },
                    {
                        prop: "station_region_name",
                        label: "台位区域",
                    },
                    {
                        prop: "station_name",
                        label: "台位号",
                    },
                    {
                        prop: "member_name",
                        label: "会员信息",
                    },
                    {
                        prop: "money",
                        label: "支付金额",
                    },
                    {
                        prop: "pay_way_name",
                        label: "支付方式",
                    },
                    // {
                    //     prop: "pay_type_name",
                    //     label: "支付渠道",
                    // },
                    {
                        prop: "bill_type",
                        label: "账单类型",
                    },
                    {
                        prop: "waiter_name",
                        label: "下单人员",
                    },
                    {
                        prop: "status",
                        label: "状态",
                    },
                    {
                        prop: "control",
                        label: "操作",
                        width: 400,
                    },
                ],
                list: [],
            },
            // 充值列表
            purcharse_cash_audit_order: {
                column_key: [
                    {
                        prop: "recharge_order_no",
                        label: "充值单号",
                    },
                    {
                        prop: "transaction_no",
                        label: "交易单号",
                    },
                    {
                        prop: "member_name",
                        label: "会员名称",
                    },
                    {
                        prop: "member_phone",
                        label: "会员手机号",
                    },
                    {
                        prop: "grade_name",
                        label: "会员等级名称",
                    },
                    {
                        prop: "recharge_amount",
                        label: "充值的金额",
                    },
                    {
                        prop: "give_money",
                        label: "赠送的金额",
                    },
                    {
                        prop: "bonus_points",
                        label: "赠送的积分",
                    },
                    {
                        prop: "pay_way_name",
                        label: "支付方式",
                    },
                    {
                        prop: "pay_status",
                        label: "支付状态",
                    },
                    {
                        prop: "created_at",
                        label: "充值时间",
                        width:100
                    },
                    {
                        prop: "control",
                        label: "操作",
                        width: 300,
                    },
                ],
                list: [],
            },
            payment_config: {
                type: 0,
                form:{},
                pay_list:[] //支付列表
            },
            pay_params:{},
            status: 3
        };
    },
    mounted() {
        this.setDefaultDate()
        this.getList();
        this.mealGetPayWayList()
    },
    watch:{
        'search_config.form.times':{
            handler(newVal){
                if(!newVal){
                    // 清空了日期，设置默认
                    this.setDefaultDate()
                }
            },
            deep:true
        },
    },
    methods: {
        /**
         * 支付方式列表
         */
        mealGetPayWayList() {
            pubDeskApi.mealGetPayWayList({
                module_type:1, //1正常 2充值 3挂账
            }).then((res) => {
                this.payment_config.pay_list = res.data.list;
            });
        },
        payChange(e){
            this.pay_params = e
        },
        /**
         * 赠送单审核  type:agree cancel
         */
        auditCancelOrder(item,type){
            let message = ''
            if(type == 'agree'){
                message = '通过'
            }else if(type == 'cancel'){
                message = '拒绝'
            }
            this.$confirm(`你确定要${message}此赠送单吗?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                if(type == 'agree'){
                    this.giveGiveBack(item)
                }else if(type == 'cancel'){
                    this.giveGiveBackExamineRefuse(item)
                }else{
                    this.$message.warning('type类型错误，非法操作')
                }
            })

            
        },
        /**
         * 退换单审核通过
         */
        giveGiveBack(item){
            pubDeskApi.giveGiveBack({
                give_bill:item.give_bill,
            }).then(res=>{
                this.$message.success('审核已通过');
                this.getList()
            })
        },
        /**
         * 退换单拒绝通过
         */
        giveGiveBackExamineRefuse(item){
            pubDeskApi.giveGiveBackExamineRefuse({
                give_bill:item.give_bill,
            }).then(res=>{
                this.$message.success('审核已拒绝');
                this.getList()
            })
        },
        recoveryAgreeFun(item){
            pubDeskApi.orderEdit({
                order_id:this.desk_dialog_config.item.id,
                open_room_id:item.id
            }).then(res=>{
                this.$message.success('修改成功');
                this.closeChangeDesk()
                this.getList()
            })
        },
        deskAgreeFun(item){
            this.recovery_config.room_id = item.id
            this.recovery_config.is_show = true
        },
        /**
         * 打开台位弹窗
         */ 
        openChangeDesk(item){
            this.desk_dialog_config.is_show = true
            this.desk_dialog_config.item = item
        },
        /**
         * 关闭台位弹窗
         */
        closeChangeDesk(){
            this.desk_dialog_config.item = null
        },
        toOrderDetailList(row){
            this.resetSearchConfig()
            this.tab_config.tab_id = '0'
            this.status = '0'
            this.search_config.form.open_room_bill = row.open_room_bill
            this.search_config.form.times = []
            this.getList()

        },
        typeChange(){
            this.search_config.form.times = []
            this.setDefaultDate()
        },
        /**
         * 更换会员
         */
        changeMember(item){
            this.member_dialog_config.item = item
            this.member_dialog_config.is_show = true
        },
        /**
         * 修改会员
         */
        memberAgreeFun(item){
            cashierApi.openRoomChangeMember({
                open_room_id:this.member_dialog_config.item.id,
                member_id:item.id
            }).then(res=>{
                this.$message.success('修改成功')
                this.staff_dialog_config.item = null
                this.getList()
            })
        },
        /**
         * 更换订台人
         */
        changeStaff(item){
            this.staff_dialog_config.item = item
            this.staff_dialog_config.is_show = true
        },
        /**
         * 修改订台人
         */
        staffAgreeFun(item){
            cashierApi.openRoomChangeStaff({
                open_room_id:this.staff_dialog_config.item.id,
                staff_id:item.id,
                staff_name:item.realname
            }).then(res=>{
                this.$message.success('修改成功')
                this.staff_dialog_config.item = null
                this.getList()
            })
        },
        /**
         * 订单导出
         */
        exportFun(){
            let {start_time,end_time} = this.handlerTimes({
                type:1
            })
            pubDeskApi.orderDetailsListExport({
                type:this.search_config.form.type,
                order_status: this.status,
                page: this.pagination.page,
                pageSize: this.pagination.page_size,
                order_number:this.search_config.form.order_number,
                station_name:this.search_config.form.station_name,
                open_room_bill:this.search_config.form.open_room_bill,
                member:this.search_config.form.member,
                sales_name:this.search_config.form.sales_name,
                waiter_name:this.search_config.form.waiter_name,
                posterior_knot_status:this.search_config.form.posterior_knot_status,
                status:this.search_config.form.status,
                start_time,
                end_time,
                is_file:true
            }).then(res=>{
                this.$message.success(`导出成功`)
            })
        },
        /**
         * 赠送单导出
         */
        giveOrderExportFun(){
            let {start_time,end_time} = this.handlerTimes({
                type:1
            })
            pubDeskApi.giveGiveOrderExport({
                time_type:this.search_config.form.type,
                member:this.search_config.form.member,
                give_bill:this.search_config.form.give_bill,
                region_name:this.search_config.form.region_name,
                room_name:this.search_config.form.room_name,
                start_time,
                end_time,
                is_file:true
            }).then(res=>{
                this.$message.success(`导出成功`)
            })
        },
        /**
         * 现金审核确认充值
         * type 2通过 4拒绝
         */
        rechargeCashConfirm(row,status){
            let message = ''
            if(status == 2){
                message = '通过'
            }else if(status == 4){
                message = '拒绝'
            }
            this.$confirm(`你确定要${message}此订单吗?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                pubDeskApi.rechargeCashConfirm({
                    id:row.id,
                    status
                }).then(res=>{
                    this.$message.success(`已${message}`)
                    this.getList();
                })
            })
        },
        /**
         * 取消订单
         */
        cancelOrder(item){
            this.$confirm(`你确定要取消此订单吗?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                pubDeskApi.orderCancelOrder({
                    id:item.id
                }).then(res=>{
                    this.$message.success('操作成功')
                    this.getList();
                })
            })
        },
        /**
         * 退货现金确认
         * status 1通过 2拒绝
         */
        cashConfirm(row,status){
            let message = ''
            if(status === 1){
                message = '通过'
            }else if(status === 2){
                message = '拒绝'
            }
            this.$confirm(`是否${message}?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                pubDeskApi.orderRevokeCashConfirm({
                    id:row.id,
                    status
                }).then(res=>{
                    this.$message.success('操作成功')
                    this.getList();
                    this.closeRefundOrderPopupConfig();
                })
            })
        },
        /**
         * 返回订单状态高亮值
         */
        statusNameChange(item){
            if(item.refund_status){
                switch(parseInt(item.refund_status)){
					case 1:
						return 'info';
					case 2:
						return 'success';
					case 3:
						return 'danger';
				}
            }else if(item.posterior_knot && item.status != 2){ // 后结
				switch(item.posterior_knot_status){
					case 1:
						return 'danger';
					case 2:
						return 'info';
					case 3:
						return 'danger';
					case 4:
						return 'info';
					case 5:
						return 'info';
					case 6:
						return 'info';
				}
			}else if(item.status){
				switch(item.status){
					case 1:
						return 'danger';
					case 2:
						return 'success';
					case 3:
						return 'info';
					case 4:
						return 'info';
				}
			}
            return 'info'
        },
        /**
         * 打开赠送单详情
         */
        opengiveGiftDetailPopup(row){
            pubDeskApi.giveShow({
                give_bill:row.give_bill,
            }).then((res) => {
                res.data['status_name'] = this.giveStatusName(res.data)
                // 赠送数据
                this.give_gift_popup_config.data = res.data
                this.openGiveGiftPopupConfig()
            });
        },
        /**
         * 打开赠送单窗口
         */
        openGiveGiftPopupConfig(){
            this.give_gift_popup_config.is_show = true
        },
        /**
         * 关闭赠送单窗口
         */
        closeGiveGiftPopupConfig(){
            this.give_gift_popup_config.is_show = false
        },
        /**
         * 打印赠送单
         */
        printGiveGift(row){
            this.$confirm(`是否打印此赠送单?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                pubDeskApi.givePrintGiveOrder({
                    give_bill:row.give_bill,
                }).then((res) => {
                    this.$message.success('调用成功')
                });
            })
        },
        /***
         * 当台订单打印
         */
        printOpenRoom(row){
            this.$confirm(`是否打印此台订单?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                pubDeskApi.openRoomPrintRoomPayInfo({
                    open_room_id:row.id,
                    open_room_bill:row.open_room_bill,
                }).then((res) => {
                    this.$message.success('调用成功')
                });
            })
        },
        showRefundAgreeFun(item){
            if(item.type === 1){
                this.refund_agree_config.is_show = true
                console.log('item',item)
                this.refund_agree_config.form.item = item
                this.refund_agree_config.form.type = ''
            }else{
                this.revokeAuditStatus(item,2)
            }
        },
        refundAgreeCancel(){
            this.refund_agree_config.is_show = false
            this.refund_agree_config.form.type = ''
            this.refund_agree_config.form.item = ''
        },
        refundAgreeOk(){
            if(!this.refund_agree_config.form.type){
                this.$message.warning('请先选择方式');
                return;
            }
            this.revokeAuditStatus(this.refund_agree_config.form.item,2)
        },
        /**
        * type 1审核过 2审核不过
        */
        orderPostClosingAudit(row,status){
            let message = ''
            if(status == 2){
                message = '通过'
            }else if(status == 2){
                message = '拒绝'
            }else if(status == 5){
                message = '通过取消订单'
            }else if(status == 6){
                message = '拒绝取消订单'
            }

            this.$confirm(`是否确定${message}?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                pubDeskApi.orderPostClosingAudit({
                    id:row.id,
                    posterior_knot_status:status //后结审核 1-待审核 2-通过 3拒绝 5通过取消订单 6拒绝取消订单
                }).then(res=>{
                    this.$message.success('操作成功')
                    this.getList();
                })
            })
        },
        renewPrintIndex(row,type){
            let message = ''
            if(type === 'product'){
                message = '出品单打印'
            }else if(type === 'pay'){
                message = '支付单打印'
            }
            this.$confirm(`是否确定${message}?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                pubDeskApi.renewPrintIndex({
                id:row.id,
                type
            }).then((res) => {
                this.$message.success('调用成功')
            });
            })
        },
        /**
         * 重置搜索 - 订单明细
         */
        async queryResetFn(){
            this.resetSearchConfig()
            await this.setDefaultDate()
            this.getList();
        },
        /**
         * 重置搜索表单
         */
        resetSearchConfig(){
            this.search_config.form.order_number = ''
            this.search_config.form.station_name = ''
            this.search_config.form.refund_type = ''
            this.search_config.form.return_order_number = ''
            this.search_config.form.open_room_bill = ''
            this.search_config.form.staff_name = ''
            this.search_config.form.member = ''
            this.search_config.form.sales_name = ''
            this.search_config.form.waiter_name = ''
            this.search_config.form.times = ''
            this.search_config.form.status = ''
            this.search_config.form.posterior_knot_status = ''
            this.search_config.form.payment_method = ''
            this.search_config.form.pay_way_id = ''
            this.search_config.form.bill_type = 0
            this.search_config.form.pay_no = ''
            this.search_config.form.station_region_name = ''
            this.search_config.form.recharge_order_no = ''
            this.search_config.form.transaction_no = ''
            this.search_config.form.room_name = ''
            this.search_config.form.user_name = ''
            this.search_config.form.phone = ''
            this.search_config.form.pay_status = 0
            this.search_config.form.credit_status = 0
            this.search_config.form.give_bill = ''
            this.search_config.form.region_name = ''
            this.resetPage()
        },
        /**
         * 搜索 - 订单明细
         */
        queryFn(){
            this.resetPage()
            this.getList();
        },
        async agreePopup() {
            await this.revokeAuditStatus(
                this.refund_order_popup_config.data,
                2
            );
            this.closeRefundOrderPopupConfig();
        },
        async rejectPopup() {
            await this.revokeAuditStatus(
                this.refund_order_popup_config.data,
                3
            );
            this.closeRefundOrderPopupConfig();
        },
        closeRefundOrderPopupConfig() {
            this.refund_order_popup_config.is_show = false;
        },
        revokeAuditStatus(item, status) {
            return new Promise((resolve, reject) => {
                let text = "";
                if (status == 2) {
                    text = "审核通过";
                } else if (status == 3) {
                    text = "审核拒绝";
                }
                this.$confirm(`是否确定${text}？`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    pubDeskApi.revokeAuditStatus({
                            id: item.id,
                            status,
                            type:status===2?this.refund_agree_config.form.type:''
                        })
                        .then((res) => {
                            resolve();
                            if (status == 2) {
                                this.$message.success("已通过");
                                this.refundAgreeCancel()
                            } else if (status == 3) {
                                this.$message.success("已拒绝");
                            }
                            this.getList();
                        });
                });
            });
        },
        /**
         * 营收
         */
        orderTurnover() {
            pubDeskApi.orderTurnover().then((res) => {
                // 处理保留两位小数 start
                res.data.total_money = toDecimal(res.data.total_money)
                res.data.actual_amount = toDecimal(res.data.actual_amount)
                for(let i in res.data.payment_detatils){
                    res.data.payment_detatils[i].money = toDecimal(res.data.payment_detatils[i].money)
                }
                // 处理保留两位小数 end
                this.market_table = res.data;
            });
        },
        handleClick() {
            this.status = this.tab_config.tab_id;
            this.resetSearchConfig()
            this.setDefaultDate()
            this.resetPage();
            this.getList();
        },
        resetPage() {
            this.pagination.page = 1;
        },
        handleClose() {},
        openRefundDetailPopup(item) {
            pubDeskApi
                .revokeShowInfo({
                    id: item.id,
                })
                .then((res) => {
                    let origin_list = [];
                    let quit_list = [];
                    let exchange_list = [];

                    for (let i in res.data.commodity) {
                        if (res.data.commodity[i].type == 1) {
                            origin_list.push(res.data.commodity[i]);
                        } else if (res.data.commodity[i].type == 2) {
                            quit_list.push(res.data.commodity[i]);
                        } else if (res.data.commodity[i].type == 3) {
                            exchange_list.push(res.data.commodity[i]);
                        }
                    }

                    if (res.data.created_at) {
                        res.data.created_at = customFormatFullTime(
                            res.data.created_at,
                            "YYYY-MM-DD h:m:s"
                        );
                    }

                    this.refund_order_popup_config.data = res.data;
                    this.refund_order_popup_config.origin_list = origin_list;
                    this.refund_order_popup_config.quit_list = quit_list;
                    this.refund_order_popup_config.exchange_list =
                        exchange_list;
                    this.refund_order_popup_config.is_show = true;
                });
        },
        /**
         * 异常收款
         */
        exception(row) {
            this.$confirm(`是否确认将此单异常收款并完成支付?`, "异常收款", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    pubDeskApi
                        .orderUnusual({
                            pay_log_id: row.id,
                        })
                        .then((res) => {
                            this.$message({
                                type: "success",
                                message: "操作成功!",
                            });
                            this.getList();
                        });
                })
                .catch(() => {});
        },
        cancelFun() {
            this.pay_params = {} //清空支付参数
            this.detail_popup_config.is_show = false;
            this.payment_config.form = null
            this.payment_config.type = 0
        },
        refreshStatus() {
            this.getList();
        },
        orderPay() {},
        refundMoney(row) {
            this.$confirm(
                `是否确认退回单号${row.order_number}现金收款`,
                "现金确认退回",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            )
                .then(() => {
                    pubDeskApi
                        .orderBackWay({
                            pay_log_id: row.id,
                        })
                        .then((res) => {
                            this.$message({
                                type: "success",
                                message: "退回成功!",
                            });
                            this.getList();
                        });
                })
                .catch(() => {});
        },
        /**
         * 现金确认
         */
        moneyArgee(row) {
            // console.log('accSub',accSub)
            // let money = calculation.accSub(row.total_money, row.actual_amount);
            this.$confirm(
                `是否确认收到单号${row.order_number}的￥${row.money}元`,
                "现金确认",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            )
                .then(() => {
                    pubDeskApi
                        .orderCashConfirm({
                            pay_log_id: row.id,
                            // order_id: row.id,
                            // money,
                        })
                        .then((res) => {
                            this.$message({
                                type: "success",
                                message: "操作成功!",
                            });
                            this.getList();
                        });
                })
                .catch(() => {});
        },
        /**
         * 交易详情
         */
        openDetailPopup(row, type) {
            this.payment_config.form = row;
            this.payment_config.type = type;


            let id = row.id
            if(this.status === '0-1' || this.status == 3){
                id = row.order_id
            }

            pubDeskApi
                .orderShowInfo({
                    id: id,
                })
                .then((res) => {
                    this.popup_config = res.data;
                    let total_config = 0;
                    for (let i in this.popup_config.commodity) {
                        total_config += parseFloat(
                            this.popup_config.commodity[i].product_price
                        );
                    }
                    this.total_config = toDecimal(total_config);
                    this.data = customFormatFullTime(
                        this.popup_config.created_at,
                        "YYYY-MM-DD h:m:s"
                    );
                    this.actual_config = toDecimal(
                        calculation.accSub(
                            calculation.accSub(
                                parseFloat(this.total_config),
                                parseFloat(this.popup_config.discount_money)
                            ),
                            parseFloat(this.popup_config.integral_money)
                        )
                    );
                    this.payment_config.amount_money = this.computedPayMoney()
                });
            this.detail_popup_config.is_show = true;
        },
        /**
         * 剩余所需支付价格
         */
        computedPayMoney(){
            return toDecimal(calculation.accSub(this.popup_config.total_money,this.popup_config.actual_amount))
        },
        getList() {
            this.orderTurnover();
            if (this.status == "0-1" || this.status == 3) {
                this.orderPayLogList();
            } else if(this.status == "50"){
                this.giveIndex();
            } else if(this.status == "48"){
                this.openRoomShowList();
            } else if(this.status == "49"){
                this.rechargeShowList();
            } else if(this.status == "47"){
                this.cleanOpenRoomList();
            } else {
                this.orderShowList();
            }
        },
        openCleanOrderDialog(item){
            this.clean_order_dialog.item = item
            this.clean_order_dialog.is_show = true
        },
        /**
         * 挂账台位列表
         */
        cleanOpenRoomList(){
            let {start_time,end_time} = this.handlerTimes({
                type:1
            })
            pubDeskApi.cleanOpenRoomList({
                page: this.pagination.page,
                pageSize: this.pagination.page_size,
                open_room_bill:this.search_config.form.open_room_bill,
                staff_name:this.search_config.form.staff_name,
                room_name:this.search_config.form.room_name,
                user_name:this.search_config.form.user_name,
                phone:this.search_config.form.phone,
                credit_status:this.search_config.form.credit_status,
                type:this.search_config.form.type,
                start_time,
                end_time
            })
            .then((res) => {
                this.pagination.total = res.data.total;
                this.clean_room.list = res.data.list;
            });
        },
        /**
         * 开台记录列表
         */
        openRoomShowList(){
            let {start_time,end_time} = this.handlerTimes({
                type:1
            })
            pubDeskApi.openRoomShowList({
                page: this.pagination.page,
                pageSize: this.pagination.page_size,
                open_room_bill:this.search_config.form.open_room_bill,
                staff_name:this.search_config.form.staff_name,
                room_name:this.search_config.form.room_name,
                user_name:this.search_config.form.user_name,
                phone:this.search_config.form.phone,
                type:this.search_config.form.type,
                start_time,
                end_time
            })
            .then((res) => {
                this.pagination.total = res.data.total;
                this.open_room.list = res.data.list;
            });
        },
        /**
         * 充值列表
         */
        rechargeShowList(){
            pubDeskApi.rechargeShowList({
                page: this.pagination.page,
                pageSize: this.pagination.page_size,
                recharge_order_no:this.search_config.form.recharge_order_no, // 充值单号
                transaction_no:this.search_config.form.transaction_no, // 交易单号
                pay_status:this.search_config.form.pay_status, // 0-全部 3-现金待确认
                member:this.search_config.form.member, //会员名称和手机号
                pay_way_id:this.search_config.form.pay_way_id, //支付方式
            })
            .then((res) => {
                this.pagination.total = res.data.total;
                let list = res.data.list;
                try {
                    for (let i in list) {
                        if(list[i].created_at){
                            list[i].created_at = customFormatFullTime(
                                list[i].created_at,
                                "YYYY-MM-DD h:m:s"
                            )
                        }
                    }
                } catch (error) { }

                this.purcharse_cash_audit_order.list = list;
            });
        },
        /**
         * 赠送列表
         */
        giveIndex(){
            let {start_time,end_time} = this.handlerTimes({
                type:1
            })
            pubDeskApi.giveIndex({
                page: this.pagination.page,
                pageSize: this.pagination.page_size,
                time_type:this.search_config.form.type,
                member:this.search_config.form.member,
                give_bill:this.search_config.form.give_bill,
                region_name:this.search_config.form.region_name,
                room_name:this.search_config.form.room_name,
                start_time,
                end_time
            })
            .then((res) => {
                this.pagination.total = res.data.total;
                let list = res.data.list;
                for(let i in list){
                    list[i]['status_name'] = this.giveStatusName(list[i])
                }
                this.gift_order.list = list;
            });
        },
        orderPayLogList() {
            let status = this.status;
            if (this.status === "0-1") {
                status = '';
            }
            let {start_time,end_time} = this.handlerTimes({
                type:1
            })
            pubDeskApi
                .orderPayLogList({
                    status: status,
                    page: this.pagination.page,
                    pageSize: this.pagination.page_size,
                    pay_no: this.search_config.form.pay_no,
                    station_region_name: this.search_config.form.station_region_name,
                    station_name: this.search_config.form.station_name,
                    waiter_name:this.search_config.form.waiter_name,
                    order_number:this.search_config.form.order_number,
                    pay_way_id:this.search_config.form.pay_way_id,
                    // payment_method:this.search_config.form.payment_method,
                    time_type:this.search_config.form.type,
                    member:this.search_config.form.member,
                    phone:this.search_config.form.phone,
                    open_room_bill:this.search_config.form.open_room_bill,
                    bill_type:this.search_config.form.bill_type,
                    start_time,
                    end_time,
                })
                .then((res) => {
                    this.pagination.total = res.data.total;
                    let list = res.data.list;
                    for (let i in list) {
                        
                        list[i].money = toDecimal(list[i].money)
                        

                        if(list[i].created_at){
                            list[i].created_at = customFormatFullTime(
                                list[i].created_at,
                                "YYYY-MM-DD h:m:s"
                            )
                        }
                    }
                    this.pay_order.list = list;
                });
        },
        /**
         * 交易账单列表
         */
        orderShowList() {
            let {start_time,end_time} = this.handlerTimes({
                type:1
            })
            pubDeskApi
                .orderShowList({
                    time_type:this.search_config.form.type,
                    type:this.search_config.form.refund_type,
                    order_status: this.status,
                    page: this.pagination.page,
                    pageSize: this.pagination.page_size,
                    order_number:this.search_config.form.order_number,
                    station_name:this.search_config.form.station_name,
                    open_room_bill:this.search_config.form.open_room_bill,
                    member:this.search_config.form.member,
                    sales_name:this.search_config.form.sales_name,
                    return_order_number:this.search_config.form.return_order_number,
                    waiter_name:this.search_config.form.waiter_name,
                    posterior_knot_status:this.search_config.form.posterior_knot_status,
                    status:this.search_config.form.status,
                    start_time,
                    end_time,
                })
                .then((res) => {
                    this.pagination.total = res.data.total;
                    let list = res.data.list;
                    for (let i in list) {
                        list[i].created_at = customFormatFullTime(
                            list[i].created_at,
                            "YYYY-MM-DD h:m:s"
                        );
                        list[i]['status_name'] = this.returnStatusName(list[i])
                        list[i]['refund_status_name'] = this.returnRefundStatusName(list[i])
                        
                    }
                    if (this.status == 4) {
                        this.refund_order.list = list;
                    } else {
                        this.trade_order.list = list;
                    }
                });
        },
        /**
         * 支付
         */
        paymentFun() {
            
            this.$refs.pay.validateParameter().then(()=>{
                pubDeskApi
                    .orderOrderPayment({
                        order_id: this.payment_config.form.id,
                        pay_id: this.pay_params.pay_id,
                        equipment_num: this.pay_params.equipment_num,
                        money: this.pay_params.amount_money,
                        paycode: this.pay_params.paycode,
                        phone:this.pay_params.phone, //手机号
                        yzcode:this.pay_params.yzcode, //验证码
                    })
                    .then((res) => {
                        if (
                            this.pay_params.payment_method == 1 ||
                            this.pay_params.payment_method == 2 ||
                            this.pay_params.payment_method == 3
                        ) {
                            this.$message.warning("请在设备上确认");
                        } else {
                            this.$message.success("操作成功");
                        }
                        this.cancelFun();
                        this.getList();
                    });
            })

        },
        handleSizeChange(page_size) {
            this.pagination.page_size = page_size;
            this.getList();
        },
        handleCurrentChange(page) {
            this.pagination.page = page;
            this.getList();
        },
    },
};
</script>
<style lang="scss" scoped>
.pub-desk-page {
    .collapse-item{
        li{
            display: flex;
            align-items: center;
            margin:6px 0px;
            .goods-img{
                width: 50px;
                height: 50px;
                border-radius: 6px;
                overflow: hidden;
                background: rgb(250, 250, 250);
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .goods-info{
                margin-left: 6px;
                .name{

                }
                .num{
                    margin-top:4px;
                }
            }
        }
    }
    .order-type{
        width:100px;
    }
    .control-btn-all{
        button{
            margin-bottom:10px;
        }
    }

    .type {
        &1 {
            color: rgb(183, 34, 34);
        }
        &2 {
            color: rgb(255, 202, 66);
        }
    }
    .top-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 20px 20px;
        background: #fff;
        height: 90px;
        box-shadow: rgba(200, 200, 200, 0.4) 0px 2px 6px 0px;
        .item {
            text-align: center;
            flex: 1;
            // padding:0px 40px;
            &:nth-child(4) {
                border-right: 1px solid rgba(128, 128, 128, 0.3);
            }
            .money {
                color: rgb(204, 20, 20);
                font-size: 20px;
                font-weight: bold;
                cursor: pointer;
            }
            .refund-money{
                color: rgb(204, 20, 20);
                font-size: 14px;
                cursor: pointer;
            }
            p {
                margin-top: 8px;
                color: rgb(16, 16, 16);
                font-size: 12px;
            }
        }
    }
    .tab-box-container {
        margin: 20px;
        padding: 20px;
        background-color: white;
    }
    .pagination-box {
        margin: 50px 0px 20px;
        text-align: center;
    }
    .dialog-bottom-info {
        margin: 30px 0px 0px;
        line-height: 1.8;
        .info {
            text-align: right;
            .active {
                color: rgba(247, 84, 110, 1);
            }
            .text {
                margin-left: 10px;
                font-weight: bold;
            }
            &.big-text {
                font-size: 20px;
            }
        }
    }

    .dialog-view-order-btn {
        margin-left: 10px;
    }
    .dialog-goods-tag {
        margin: 30px 0px;
    }
    & /deep/ .el-dialog__footer {
        text-align: center;
        width: 100%;
    }
}
.payment {
    margin: 10px auto;
    line-height: 20px;
}
.menthod {
    margin: 10px auto;
    text-align: center;
    .pay-item {
        margin-bottom: 6px;
    }
}
.serial-code {
    margin: 10px auto;
    width: 400px;
}
.serial-select {
    width: 200px;
    margin: 10px auto;
}
.refund_label{
    background-color: #f7090d;
    color: white;
    padding:4px;
    border-radius:2px;
    margin-left:10px;
    line-height:1;
    font-size:12px;
    display: inline-flex;
    align-items: center;
    &.huan{
        background-color: rgb(143, 208, 117);
    }
}
</style>