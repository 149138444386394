<template>
    <div class="desk-dialog-component">
        <el-dialog
            v-model="dialog_config.is_show"
            :title="dialog_config.title"
            width="300px"
            :show-close="false"
            :close-on-click-modal="false"
            :close-on-press-escape="false">
            <el-form :model="dialog_config.form" ref="selectForm">
                <el-form-item prop='room_id'>
                    <el-select v-model="dialog_config.form.room_id" placeholder="请选择" class="bottom">
                        <el-option v-for="item in dialog_config.desktop_list" :key="item.value" :value="item.id" :label="item.ro_room_name + ' - ' + item.staff_name"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button @click="cancelHandle">取消</el-button>
                    <el-button type="primary" @click="agreeHandle">确认台位</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
import tableComponent from '@/components/table'
import {cashierApi} from '@/api'
import { nextTick } from 'vue'
export default {
    name:'desk-dialog-component',
    components:{
        tableComponent
    },
    props:{
        is_show:[Boolean]
    },
    watch:{
        is_show:{
            handler(newVal){
                this.dialog_config.is_show = newVal
                if(newVal){
                    nextTick(()=>{
                        this.getList()
                    })
                }
            },
            immediate:true,
            deep:true
        },
        'dialog_config.is_show':{
            handler(newVal){
                this.$emit('update:is_show',newVal)
            },
            deep:true
        }
    },
    data(){
        return{
            dialog_config:{
                title:'选择台位',
                is_show:false,
                form:{
                    room_id:'' //台位id
                },
                desktop_list:[]
            },
        }
    },
    methods:{
        /**
         * 获取台位列表
         */
        getList(){
            cashierApi.roomIndex({
                keyword:''
            }).then(res=>{
                let list = []
                for(let i in res.data.list){
                    for(let b in res.data.list[i].list){
                        list.push(res.data.list[i].list[b])
                    }
                }
                this.dialog_config.desktop_list = list
            })
        },
        /**
         * 只允许选开台的
         */
        deskDisabaled(item){
            if(item.open_room_id){
                return false
            }
            return true
        },
        resetForm(){
            this.dialog_config.form.room_id = ''
        },
        cancelHandle(){
            this.resetForm()
            this.dialog_config.is_show = false
        },
        /**
         * 通过台位id获取台位信息
         */
        getCurrentDeskInfo(id){
            for(let i in this.dialog_config.desktop_list){
                if(this.dialog_config.desktop_list[i].id == id){
                    return this.dialog_config.desktop_list[i]
                }
            }
            return null
        },
        agreeHandle(){
            if(!this.dialog_config.form.room_id){
                this.$message.warning('请先选择台位')
                return;
            }
            let item = this.getCurrentDeskInfo(this.dialog_config.form.room_id)
            this.$emit('agree',item)
            this.cancelHandle()
        },
    }
}
</script>
<style lang="scss" scoped>
.desk-dialog-component{
   
}
</style>