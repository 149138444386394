<template>
    <div class="goods-list-table-component">
        <el-table :data="list"
            stripe
            border
            style="width: 100%" >
            <el-table-column type="expand">
                <template #default="scope">
                    <ul class="collapse-item">
                        <li v-for="(package_item,package_index) of scope.row.package" :key="package_index">
                            <div class="goods-img">
                                <img v-if="package_item.product_pic" :src="package_item.product_pic">
                                <img v-else :src="default_img">
                                
                            </div>
                            <div class="goods-info">
                                <span class="name">{{package_item.product_name}}</span>
                                <span class="num">*{{package_item.product_num}}</span>
                            </div>
                        </li>
                    </ul>
                </template>
            </el-table-column>
            <el-table-column
                header-align="center"
                align="center"
                v-for="(item, index) of keys"
                :key="index"
                :prop="item.prop"
                :label="item.label"
                :width="item.width ? item.width : null"
            >
                <template #default="scope">
                    <div v-if="item.prop === 'product_pic'">
                        <img class="goods-pic" v-if="scope.row.product_pic" :src="scope.row.product_pic" />
                        <img class="goods-pic" v-else :src="default_img" />
                    </div>
                    <div v-else>
                        {{ scope.row[item.prop] }}
                    </div>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>
<script>
import {default_img} from '@/config'
export default {
    name: "goods-list-table-component",
    props:{
        list:{
            default:[],
            type:Array
        },
        keys:{
            default:[],
            type:Array
        }
    },
    data(){
        return{
            default_img:default_img
        }
    }
};
</script>
<style lang="scss" scoped>
.goods-list-table-component{
    .goods-pic{
        width:40px;
        height:40px;
    }
}
</style>