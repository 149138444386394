<template>
    <div class="clean-order-dialog-component">
        <el-dialog class="view-detail-dialog" 
            :title="clean_order_dialog.title" 
            v-model="clean_order_dialog.is_show" 
            width="1000px" 
            :show-close="false"
            :close-on-click-modal="false"
            :close-on-press-escape="false">
            <div>
                <div class="dialog-title">挂账数据</div>
                <div class="member-info">
                    <div class="dialog-info-item">
                        <span>挂账总金额：{{payment_config.check_total_price}}</span>
                        <span>已支付金额：{{payment_config.pay_price}}</span>
                    </div>
                    <div class="dialog-info-item">
                        <span>还需支付金额：{{payment_config.wait_pay_price}}</span>
                    </div>
                </div>
            </div>
            <div>
                <div class="dialog-title">订单列表</div>
                <div class="member-info" v-if="clean_order_dialog.table.list && clean_order_dialog.table.list.length>0">
                    <table-component :table="clean_order_dialog.table">
                        <template #expand="scope">
                            <div v-if="scope.scope.row && scope.scope.row.product && scope.scope.row.product.length>0">
                                <ul class="collapse-item">
                                    <li v-for="(product_item,product_index) of scope.scope.row.product" :key="product_index">
                                        <div class="goods-img">
                                            <img v-if="product_item.product_pic" :src="product_item.product_pic">
                                            <img v-else :src="default_img">
                                        </div>
                                        <div class="goods-info">
                                            <span class="name">{{product_item.product_name}}</span>
                                            <span class="num global-ml-10">*{{product_item.product_num}}</span>
                                        </div>
                                        <div class="global-ml-20">
                                            <span class="price">￥{{product_item.product_price}}</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </template>
                        <template #default="scope">
                            <div v-if="scope.item.prop == 'num'">{{scope.scope.row.product?scope.scope.row.product.length:0}}</div>
                            <div v-else-if="scope.item.prop == 'status'">
                                <el-tag type="info" v-if="scope.scope.row[scope.item.prop] == 1">未结清</el-tag>
                                <el-tag type="primary" v-else-if="scope.scope.row[scope.item.prop] == 2">已结清</el-tag>
                                <el-tag type="warning" v-else-if="scope.scope.row[scope.item.prop] == 3">待确认</el-tag>
                                <span v-else>--</span>
                            </div>
                            <div v-else>{{scope.scope.row[scope.item.prop]}}</div>
                        </template>
                    </table-component>
                </div>
            </div>
            <div class="pay-box" v-if="payment_config.is_show_pay">
                <div class="dialog-title">支付方式</div>
                <payComponent ref="pay" :pay_moeny="payment_config.wait_pay_price" :module_type="3" @payChange="payChange"/>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="closeCleanOrderDialog">关闭</el-button>
                    <el-button type="primary" @click="payHandle" v-if="payment_config.is_show_pay">支付</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>
<script>
import {default_img} from '@/config'
import tableComponent from '@/components/table'
import {pubDeskApi} from '@/api'
import calculation from "@/assets/js/calculation";
import { compareTime, customFormatFullTime, toDecimal } from "@/assets/js/utils";
import payComponent from '@/components/pay'

export default {
    name:'clean-order-dialog-component',
    components:{
        tableComponent,
        payComponent
    },
    props:{
        is_show:[Boolean],
        open_room_id:{
            default:'',
            type:[String,Number]
        }
    },
    watch:{
        is_show:{
            handler(newVal){
                this.clean_order_dialog.is_show = newVal
                if(newVal){
                    this.$nextTick(()=>{
                        this.getList();
                    })
                }
            },
            immediate:true,
            deep:true
        },
        'clean_order_dialog.is_show':{
            handler(newVal){
                this.$emit('update:is_show',newVal)
            },
            deep:true
        },
        'payment_config.wait_pay_price':{
            handler(newVal){
                if(newVal > 0){
                    this.payment_config.is_show_pay = true
                }else{
                    this.payment_config.is_show_pay = false
                }
            },
            deep:true
        }
    },
    data(){
        return{
            default_img:default_img,
            /**
             * 挂账列表
             */
            clean_order_dialog:{
                title:'挂账列表',
                is_show:false,
                table:{
                    is_expand:true,
                    column_key: [
                        {
                            prop: "order_number",
                            label: "订单编号"
                        },
                        {
                            prop: "open_room_bill",
                            label: "开台编号"
                        },
                        {
                            prop: "credit_person_name",
                            label: "挂账人"
                        },
                        {
                            prop: "num",
                            label: "商品个数"
                        },
                        {
                            prop: "credit_money",
                            label: "挂账金额"
                        },
                        {
                            prop: "clear_money",
                            label: "已支付金额"
                        },
                        {
                            prop: "status",
                            label: "状态"
                        },
                    ],
                    list: [],
                }
            },
            payment_config: {
                is_show_pay:false,
                check_order_num:0, //订单数
                check_total_price:0, //挂账总金额
                pay_price:0, //已支付金额
                wait_pay_price:'', //还需支付金额
            },
            pay_params:{ //支付组件返回的参数

            }
        }
    },
    methods:{
        payChange(e){
            this.pay_params = e
        },
        payHandle(){

            this.$refs.pay.validateParameter().then(()=>{
                let ids = []
                let list = this.clean_order_dialog.table.list
                for(let i in list){
                    if(list[i].status == 1){
                        ids.push(list[i].id)
                    }
                }

                if(ids.length<=0){
                    this.$message.warning('当前无订单可支付，可能在待确认')
                    return;
                }
    
                pubDeskApi.cleanClearingRecord({
                    order_credit_id:ids.toString(),
                    money:this.pay_params.amount_money, //支付金额
                    pay_id:this.pay_params.pay_id, //支付方式id
                    paycode:this.pay_params.paycode, //支付编号
                    equipment_num:this.pay_params.equipment_num, //POS机终端号
                    phone:this.pay_params.phone, //手机号
                    yzcode:this.pay_params.yzcode, //验证码
                }).then(res=>{
                    this.closeCleanOrderDialog()
                    if(this.pay_params.pay_id == 4){
                        this.$message.success('操作成功，请在收银台确认')
                    }else{
                        this.$message.success('操作成功')
                    }
                })
            })

        },
    
        /**
         * 获取挂账商品列表
         */
        getList(){
            pubDeskApi.cleanShowList({
                open_room_id:this.open_room_id,
            })
            .then((res) => {
                this.clean_order_dialog.table.list = res.data;
                this.computedPrice()
            });
        },
        /**
         * 计算挂账总金额
         */
        computedPrice(){
            let list = this.clean_order_dialog.table.list

            let check_order_num = list.length //订单总数
            let check_total_price = 0 //挂账总金额
            let pay_price = 0 //已支付金额
            let wait_pay_price = 0 //还需支付金额

            for(let i in list){
                check_total_price =  calculation.accAdd(check_total_price,parseFloat(list[i].credit_money))
                pay_price = calculation.accAdd(pay_price,parseFloat(list[i].clear_money || 0))

            }
            

            // 剩余所需支付价格 = 总价 - 已支付价格
            wait_pay_price = calculation.accSub(check_total_price,pay_price)
            
            this.payment_config.check_order_num = check_order_num
            this.payment_config.check_total_price = check_total_price
            this.payment_config.pay_price = pay_price
            this.payment_config.wait_pay_price = wait_pay_price
        },
        checkItem(item,preItem){
            this.check_item = item
        },
        handleSizeChange(page_size){
            this.pagination.page_size = page_size
            this.getList()
        },
        handleCurrentChange(page){
            this.pagination.page = page
            this.getList()
        },
        openCleanOrderDialog(){
            this.clean_order_dialog.is_show = true
        },
        closeCleanOrderDialog(){
            this.clean_order_dialog.is_show = false
            this.clean_order_dialog.table.list = []
            this.pay_params = {} //清空支付参数
            this.payment_config.wait_pay_price = 0
            this.payment_config.is_show_pay = false

        },
    }
}
</script>
<style lang="scss" scoped>
.clean-order-dialog-component{
    .collapse-item{
        li{
            display: flex;
            align-items: center;
            margin:6px 0px;
            .goods-img{
                width: 40px;
                height: 40px;
                border-radius: 6px;
                overflow: hidden;
                background: rgb(250, 250, 250);
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .goods-info{
                margin-left: 6px;
                .name{

                }
                .num{
                    margin-top:4px;
                }
            }
        }
    }
    .pay-box{
        margin-top:40px;
        .pay-form{
            width: 400px;
        }
    }
}
</style>